<template>
  <div class="flex flex-col max-w-md items-center mx-auto p-5">
    <div v-if="groupInvalid" class="text-center">
      Zu dieser URL gibt es keine Wetten oder sie wurden gelöscht
    </div>
    <div v-else-if="!bets">
      ... Wetten werden geladen ...
    </div>
    <template v-else>
      <router-link
        v-for="(bet) in bets"
        :key="bet.id"
        class="text-white my-5 border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="bet.backgroundColor"
        :to="`/${group}/${bet.id}`"
      >
        <div class="h-15 font-bold text-2xl mb-5">
          {{ bet.name }}
        </div>
        <div v-if="!bet.correct_answers" class="flex flex-wrap">
          <div class="flex-grow mb-3">
            Errechneter Geburtstermin: <b>{{ bet.expected_date_of_birth }}</b>
          </div>
          <div class="flex-grow" v-html="bet.last_possible_bet_date">
          </div>
        </div>
        <div v-else class="text-center">
          <b>🎉 Das Baby ist da! 🎉</b>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import { Directus } from '@directus/sdk'
import dayjs from 'dayjs'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'Group',
  props: {
    group: String,
  },
  data() { return {
    bets: null,
    groupInvalid: false,
  }},
  async created() {
    if (this.group) {
      const { data: betGroups } = await directus.items('bet_group').readMany({
        fields: 'bets.*.*',
        filter: {
          url_slug: {
            _eq: this.group
          }
        }
      })
      if (betGroups[0]) {
        this.bets = betGroups[0].bets.map(({ expected_date_of_birth, last_possible_bet_date, color, ...bet }) => {
          let lastPossibleBetDateString = `Wetten werden bis <b>${dayjs(last_possible_bet_date).format('DD.MM.YYYY')}</b> angenommen`
          if (dayjs(last_possible_bet_date).diff(dayjs(), 'day') < 0 && !bet.correct_answers) {
            lastPossibleBetDateString = 'Wetten ist nicht mehr möglich.'
          }
          let backgroundColor = ''
          switch(color) {
            case 'gray':
              backgroundColor = 'from-gray-600 to-gray-500 hover:from-gray-400'
              break
            case 'red':
              backgroundColor = 'from-gray-600 to-red-500 hover:from-red-400'
              break
            case 'yellow':
              backgroundColor = 'from-gray-600 to-yellow-500 hover:from-yellow-400'
              break
            case 'blue':
              backgroundColor = 'from-gray-600 to-blue-500 hover:from-blue-400'
              break
            case 'indigo':
              backgroundColor = 'from-gray-600 to-indigo-500 hover:from-indigo-400'
              break
            case 'purple':
              backgroundColor = 'from-gray-600 to-purple-500 hover:from-purple-400'
              break
            case 'pink':
              backgroundColor = 'from-gray-600 to-pink-500 hover:from-pink-400'
              break
            case 'green':
            default:
              backgroundColor = 'from-gray-600 to-green-500 hover:from-green-400'
          }
          return {
            ...bet,
            expected_date_of_birth: dayjs(expected_date_of_birth).format('DD.MM.YYYY'),
            last_possible_bet_date: lastPossibleBetDateString,
            backgroundColor,
          }
        })
      } else {
        this.groupInvalid = true 
      }
    }
  },
  computed: {
  },
}
</script>
