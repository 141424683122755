<template>
  <div v-if="showPointInfo" class="top-0 fixed flex w-screen h-screen justify-center items-center bg-black bg-opacity-80 z-10" @click="togglePointInfo">
    <div class="rounded-xl bg-white w-full max-w-md p-10 border-2" :class="dynamicColors.border" @click.stop>
      <div class="text-lg font-medium text-center mb-10">Wie wird ausgewertet?</div>
      <div v-if="bet.options.includes('firstname')" class="mb-5">
        <i class="font-medium">Vorname:</i>
        <div>
          Alle die den exakten Vorname getippt haben bekommen einen Punkt. Hat niemand den richtigen Vornamen, dann bekommen alle die den richtigen Anfangsbuchstaben getippt haben einen halben Punkt.
        </div>
      </div>
      <div v-if="bet.options.includes('weight') || bet.options.includes('size') || bet.options.includes('birthday') || bet.options.includes('birthtime')" class="mb-5">
        <i class="font-medium">Gewicht, Größe, Geburtstag und Geburtszeit:</i>
        <div>
          Die Tipps die gleich dem Ergebnis sind bzw. am nächsten dem Ergebnis liegen bekommen einen Punkt.
        </div>
      </div>
      <div class="text-center">
        <button
          @click="togglePointInfo"
          class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
          :class="dynamicColors.button"
        >
          Schließen
        </button>
      </div>
    </div>
  </div>
  <Breadcrumps />
  <div class="flex flex-col mx-auto items-center my-10 px-3 min-h-screen">
    <div v-if="!bet">
      ... loading ...
    </div>
    <div v-else-if="saving">
      ... Wette wird gespeichert ...
    </div>
    <div v-else-if="!lastPossibleBetDate.possible || !!bet.correct_answers">
      <div>
        Wetten ist nicht mehr möglich
      </div>
      <div v-if="!lastPossibleBetDate.possible">
        Wetten wurden nur bis <b>{{ lastPossibleBetDate.date }}</b> angenommen
      </div>
      <div v-else>
        Da das Baby schon da ist, können keine Wetten mehr abgegeben werden.
      </div>
    </div>
    <form v-else @submit.prevent="setBet" class="px-1">
      <div
        class="text-2xl text-center text-white mb-10 border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="dynamicColors.title"
      >
        {{ bet.name }}
      </div>
      <div class="font-bold text-2xl text-center">
        Neuen Tipp abgeben
      </div>
      <div class="text-sm text-gray-500 text-center mb-5">
        Dein Tipp kann nachdem du ihn gespeichert hast nicht mehr geändert werden!
      </div>
      <div class="text-sm text-gray-500 text-center mb-10 hover:text-black">
        <button type="button" @click="togglePointInfo" class="underline cursor-pointer">
          Wie wird ausgewertet?
        </button>
      </div>
      <div class="text-xl text-center mb-5">
        Geplanter Geburtstermin: <b>{{ expectedDateOfBirth }}</b>
      </div>
      <div class="max-w-md py-5 mx-auto">
        <div class="border-black border-b pb-5 mb-5">
          <div>
            Dein Name*
          </div>
          <div class="text-sm text-gray-500">
            Sollte eindeutig zu erkennen sein.
          </div>
          <div class="mt-1">
            <input type="text" class="border border-black rounded p-2 w-full" placeholder="Dein Name" required v-model="name_person" />
          </div>
        </div>
        <div class="border-black border-b pb-5 mb-5">
          <div>
            E-Mail Adresse <span class="text-gray-500">(optional)</span>
          </div>
          <div class="text-sm text-gray-500">
            Dann bekommst du das Ergebnis und Updates per Mail zugesendet. Deine E-Mail Adresse ist für andere Teilnehmende nicht sichtbar.
          </div>
          <div class="mt-1">
            <input type="email" class="border border-black rounded p-2 w-full" placeholder="E-Mail" v-model="email" />
          </div>
        </div>
        <div class="text-2xl mb-10">
          Dein Tipp
        </div>
        <div class="text-center font-bold mb-1">Beschreibung:</div>
        <div class="max-w-md mx-auto mb-10">
          {{ bet.description }}
        </div>
        <div v-if="bet.options.includes('firstname')" class="border-black border-b pb-5 mb-5">
          <div>
            Vorname*
          </div>
          <div class="text-sm text-gray-500">
            Wie wird der Vorname des Babys sein?
          </div>
          <div v-if="this.gender != 'unknown'" class="text-sm text-gray-500">
            Das Baby wird voraussichtlich ein <b>{{ this.gender == 'male' ? 'Bub' : 'Mädchen' }}</b>.
          </div>
          <div class="flex mt-1">
            <input type="text" class="border border-black rounded p-2 flex-1" :placeholder="`z.B.: ${randomFirstname}`" required v-model="firstname" />
            <button type="button" class="outline-none ml-2" @click="getRandomFirstname()">🔄</button>
          </div>
        </div>
        <div v-if="bet.options.includes('weight')" class="border-black border-b pb-5 mb-5">
          <div>
            Gewicht*
          </div>
          <div class="text-sm text-gray-500">
            In Gramm ohne Kommastelle
          </div>
          <div class="mt-1">
            <input type="number" class="border border-black rounded p-2 w-full" placeholder="Gewicht in g" required v-model="weight" />
          </div>
        </div>
        <div v-if="bet.options.includes('size')" class="border-black border-b pb-5 mb-5">
          <div>
            Größe*
          </div>
          <div class="text-sm text-gray-500">
            In Zentimeter ohne Kommastelle
          </div>
          <div class="mt-1">
            <input type="number" class="border border-black rounded p-2 w-full" placeholder="Größe in cm" required v-model="size" />
          </div>
        </div>
        <div v-if="bet.options.includes('birthday')" class="border-black border-b pb-5 mb-5">
          <div>
            Geburtstag*
          </div>
          <div class="text-sm">
            Geplanter Geburtstermin: <b>{{ expectedDateOfBirth }}</b>
          </div>
          <div class="mt-1">
            <input type="date" class="border border-black rounded p-2 w-full" placeholder="Geburtstag" :min="minBirthday" :max="maxBirthday" required v-model="birthday" />
          </div>
        </div>
        <div v-if="bet.options.includes('birthtime')" class="border-black border-b pb-5 mb-5">
          <div>
            Geburtszeit*
          </div>
          <div class="mt-1">
            <input type="time" class="border border-black rounded p-2 w-full" placeholder="Geburtszeit" required v-model="birthtime" step="60" />
          </div>
        </div>
        <div class="text-sm text-gray-500 mb-2">
          Du darfst nur einmal mitwetten, wenn du deinen Tipp jetzt sendest, kannst du ihn nicht mehr ändern. Wetten werden bis <b>{{ lastPossibleBetDate.date }}</b> angenommen.
        </div>
        <div class="flex flex-col sm:flex-row">
          <router-link :to="`/${group}/${betId}`" custom v-slot="{ navigate }">
            <button
              type="button"
              @click="navigate"
              class="flex justify-center items-center bg-gray-400 flex-1 rounded mr-0 sm:mr-5 hover:bg-opacity-90 p-4 order-2 sm:order-1 text-white"
            >
              Abbrechen
            </button>
          </router-link>
          <button
            type="submit"
            class="flex justify-center items-center bg-gray-400 flex-1 rounded hover:bg-opacity-90 p-4 bg-gradient-to-r text-white mb-5 sm:mb-0 order-1 sm:order-2"
            :class="dynamicColors.button"
          >
            Wette platzieren
          </button>
        </div>
      </div>
    </form>
  </div>
  <Breadcrumps />
</template>

<script>
import dayjs from 'dayjs'
import { Directus } from '@directus/sdk'
import Breadcrumps from '../components/breadcrumps'
import vornamenM from '../vornamen/vornamen_m_koeln_2017'
import vornamenW from '../vornamen/vornamen_w_koeln_2017'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'SetBet',
  components: {
    Breadcrumps,
  },
  props: {
    group: String,
    betId: String,
    getAllBets: Function,
  },
  data: () => ({
    name_person: '',
    email: '',
    firstname: '',
    weight: '',
    size: '',
    birthday: '',
    birthtime: '',
    saving: false,
    bet: null,
    randomFirstname: '',
    gender: 'unknown',
    showPointInfo: false,
  }),
  async created() {
    this.bet = await directus.items('bet').readOne(this.betId)
    if (this.bet.gender !== 'unknown') {
      this.gender = this.bet.gender
    }
    this.getRandomFirstname()
  },
  computed: {
    expectedDateOfBirth() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).format('DD.MM.YYYY')
      } else {
        return '-'
      }
    },
    minBirthday() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).subtract(1, 'month').format('YYYY-MM-DD')
      } else {
        return null
      }
    },
    maxBirthday() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).add(1, 'months').format('YYYY-MM-DD')
      }
      return null
    },
    lastPossibleBetDate() {
      if (this.bet) {
        return {
          date: dayjs(this.bet.last_possible_bet_date).format('DD.MM.YYYY'),
          possible: dayjs(this.bet.last_possible_bet_date).diff(dayjs(), 'day') >= 0,
        }
      }
      return null
    },
    dynamicColors() {
      if (this.bet) {
        switch(this.bet.color) {
          case 'gray':
            return {
              border: 'border-gray-500',
              title: 'from-gray-600 to-gray-500',
              button: 'to-gray-500 from-gray-500 hover:from-gray-600 hover:to-gray-600',
            }
          case 'red':
            return {
              border: 'border-red-500',
              title: 'from-gray-600 to-red-500',
              button: 'to-red-500 from-red-500 hover:from-red-600 hover:to-gray-600',
            }
          case 'yellow':
            return {
              border: 'border-yellow-500',
              title: 'from-gray-600 to-yellow-500',
              button: 'to-yellow-500 from-yellow-500 hover:from-yellow-600 hover:to-gray-600',
            }
          case 'blue':
            return {
              border: 'border-blue-500',
              title: 'from-gray-600 to-blue-500',
              button: 'to-blue-500 from-blue-500 hover:from-blue-600 hover:to-gray-600',
            }
          case 'indigo':
            return {
              border: 'border-indigo-500',
              title: 'from-gray-600 to-indigo-500',
              button: 'to-indigo-500 from-indigo-500 hover:from-indigo-600 hover:to-gray-600',
            }
          case 'purple':
            return {
              border: 'border-purple-500',
              title: 'from-gray-600 to-purple-500',
              button: 'to-purple-500 from-purple-500 hover:from-purple-600 hover:to-gray-600',
            }
          case 'pink':
            return {
              border: 'border-pink-500',
              title: 'from-gray-600 to-pink-500',
              button: 'to-pink-500 from-pink-500 hover:from-pink-600 hover:to-gray-600',
            }
          case 'green':
          default:
            return {
              title: 'from-gray-600 to-green-500',
              button: 'to-green-500 from-green-500 hover:from-green-600 hover:to-gray-600',
            }
        }
      }
      return {}
    },
    vornamen() {
      let array = vornamenM
      if (this.gender === 'female') {
        array = vornamenW
      } else if (this.gender === 'unknown') {
        array = [...vornamenM, ...vornamenW]
      }
      return array
    }
  },
  methods: {
    async setBet() {
      this.saving = true
      const bet = {
        bet_id: this.bet.id,
        name_person: this.name_person,
        email: this.email,
      }
      if (this.bet.options.includes('firstname')) {
        bet.bet_firstname = this.firstname
      }
      if (this.bet.options.includes('weight')) {
        bet.bet_weight = Number(this.weight)
      }
      if (this.bet.options.includes('size')) {
        bet.bet_size = Number(this.size)
      }
      if (this.bet.options.includes('birthday')) {
        bet.bet_birthday = this.birthday
      }
      if (this.bet.options.includes('birthtime')) {
        bet.bet_birthtime = `${this.birthtime}:00`
      }

      await directus.items('bet_entry').createOne(bet)
      this.$router.push(`/${this.group}/${this.betId}/setSuccess`)
    },
    getRandomFirstname() {
      this.randomFirstname = this.vornamen[Math.floor(Math.random() * this.vornamen.length)]
    },
    togglePointInfo() {
      this.showPointInfo = !this.showPointInfo
      if (this.showPointInfo) {
        disableBodyScroll(this.$el)
      } else {
        enableBodyScroll(this.$el)
      }
    },
  }
}
</script>