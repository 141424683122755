<template>
  <div class="min-h-screen flex flex-col">
    <div v-if="showPointInfo" class="top-0 fixed flex w-screen h-screen justify-center items-center bg-black bg-opacity-80 z-10" @click="togglePointInfo">
      <div class="rounded-xl bg-white w-full max-w-md p-10 border-2" :class="dynamicColors.border" @click.stop>
        <div class="text-lg font-medium text-center mb-10">Wie wurde ausgewertet?</div>
        <div v-if="bet.options.includes('firstname')" class="mb-5">
          <i class="font-medium">Vorname:</i>
          <div>
            Alle die den exakten Vorname getippt haben bekommen einen Punkt. Hat niemand den richtigen Vornamen, dann bekommen alle die den richtigen Anfangsbuchstaben getippt haben einen halben Punkt.
          </div>
        </div>
        <div v-if="bet.options.includes('weight') || bet.options.includes('size') || bet.options.includes('birthday') || bet.options.includes('birthtime')" class="mb-5">
          <i class="font-medium">Gewicht, Größe, Geburtstag und Geburtszeit:</i>
          <div>
            Die Tipps die gleich dem Ergebnis sind bzw. am nächsten dem Ergebnis liegen bekommen einen Punkt.
          </div>
        </div>
        <div class="text-center">
          <button
            @click="togglePointInfo"
            class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
            :class="dynamicColors.button"
          >
            Schließen
          </button>
        </div>
      </div>
    </div>
    <Breadcrumps />
    <div v-if="!bet"  class="max-w-md mx-auto p-5">
      ... loading ...
    </div>
    <div v-else class="flex flex-col mx-auto items-center my-10 px-3">
      <div
        class="text-2xl text-center mb-5 text-white border p-5 cursor-pointer rounded-xl bg-gradient-to-r"
        :class="dynamicColors.title"
      >
        {{ bet.name }}
      </div>
      <template v-if="bet.correct_answers">
        <div class="font-bold mb-2 text-4xl text-center">
          Ergebnis
        </div>
        <div class="mx-auto flex flex-col items-center text-lg">
          <div v-if="bet.options.includes('firstname')">
            <i>Vorname:</i> <b>{{ bet.correct_answers.bet_firstname }}</b>
          </div>
          <div v-if="bet.options.includes('weight')">
            <i>Gewicht:</i> {{ bet.correct_answers.bet_weight }}g
          </div>
          <div v-if="bet.options.includes('size')">
            <i>Größe:</i> {{ bet.correct_answers.bet_size }}cm
          </div>
          <div v-if="bet.options.includes('birthday')">
            <i>Geburtstag:</i> {{ correctBirthday }}
          </div>
          <div v-if="bet.options.includes('birthtime')">
            <i>Geburtszeit:</i> {{ correctBirthtime }}
          </div>
        </div>
      </template>
      <template v-if="winnerParticipants && winnerParticipants.length">
        <div class="mt-10 font-bold mb-2 text-4xl text-center">
          Sieger:innen
        </div>
        <div class="mx-auto p-5 flex flex-wrap justify-start max-w-5xl">
          <div
            v-for="(answer, key) in winnerParticipants"
            :key="`answer${key}`"
            class="rounded p-5 flex-1 mr-5 mb-5 whitespace-nowrap bg-gradient-to-r text-white"
            :class="dynamicColors.title"
          >
            <div class="font-bold">
              {{ answer.name_person }}
            </div>
            <div v-if="bet.correct_answers" class="pl-5 mt-3">
              <ul class="list-disc list-outside">
                <li v-if="bet.options.includes('firstname')">
                  <i>Vorname:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_firstname') }">{{ answer.bet_firstname }}</span>
                </li>
                <li v-if="bet.options.includes('weight')">
                  <i>Gewicht:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_weight') }">{{ answer.bet_weight }}g</span>
                </li>
                <li v-if="bet.options.includes('size')">
                  <i>Größe:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_size') }">{{ answer.bet_size }}cm</span>
                </li>
                <li v-if="bet.options.includes('birthday')">
                  <i>Geburtstag:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_birthday') }">{{ answer.bet_birthday }}</span>
                </li>
                <li v-if="bet.options.includes('birthtime')">
                  <i>Geburtszeit:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_birthtime') }">{{ answer.bet_birthtime }}</span>
                </li>
                <li>
                  <i>Punkte:</i> {{ answer.points }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button @click="togglePointInfo" class="text-gray-500 cursor-pointer underline hover:text-black">
          Wie wurde ausgewertet?
        </button>
      </template>
      <div class="mt-10 font-bold mb-10 text-4xl text-center">
        Teilnehmende
      </div>
      <div v-if="bet.answers.length" class="w-full mx-auto max-w-md relative">
        <input
          type="text"
          v-model="search"
          placeholder="Teilnehmende suchen..."
          class="border border-black rounded p-2 w-full"
        />
        <button v-if="search" type="button" @click="clearSearch" class="absolute top-2 right-2 bg-gray-400 rounded-full w-7 h-7 text-white hover:bg-gray-500">
          X
        </button>
      </div>
      <div v-if="!bet.answers.length">
        Leider hat noch niemand teilgenommen :(
        <div>
          Komm mach mit!
        </div>
        <div v-if="!bet.correct_answers" class="text-center mt-5 mb-20">
          <router-link
            :to="`/${group}/${betId}/set`"
            class="text-center text-whiteborder p-2 text-md cursor-pointer rounded bg-gradient-to-r mx-auto text-white"
            :class="dynamicColors.button"
          >
            Neue Wette abgeben
          </router-link>
        </div>
      </div>
      <div v-else class="md:mx-auto mx-5 md:justify-center p-5 flex flex-col md:flex-row flex-wrap justify-start items-start w-full max-w-5xl min-h-screen content-start">
        <div v-if="!searchedAnswers.length">
          Keine Teilnehmenden unter dem Begriff <b>"{{search}}"</b> gefunden
        </div>
        <div
          v-for="(answer, key) in searchedAnswers"
          :key="`answer${key}`"
          class="border rounded border-black p-5 flex-1 mx-auto md:mr-5 mb-5 whitespace-nowrap w-full"
          :class="[ bet.correct_answers ? 'max-h-56' : 'max-h-16' ]"
        >
          <div class="font-bold">
            {{ answer.name_person }}
          </div>
          <div v-if="bet.correct_answers" class="pl-5 mt-3">
            <ul class="list-disc list-outside">
              <li v-if="bet.options.includes('firstname')">
                <i>Vorname:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_firstname') }">{{ answer.bet_firstname }}</span>
              </li>
              <li v-if="bet.options.includes('weight')">
                <i>Gewicht:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_weight') }">{{ answer.bet_weight }}g</span>
              </li>
              <li v-if="bet.options.includes('size')">
                <i>Größe:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_size') }">{{ answer.bet_size }}cm</span>
              </li>
              <li v-if="bet.options.includes('birthday')">
                <i>Geburtstag:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_birthday') }">{{ answer.bet_birthday }}</span>
              </li>
              <li v-if="bet.options.includes('birthtime')">
                <i>Geburtszeit:</i> <span :class="{ 'font-bold': answer.categories.includes('bet_birthtime') }">{{ answer.bet_birthtime }}</span>
              </li>
              <li>
                <i>Punkte:</i> {{ answer.points }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mb-10 text-gray-600 max-w-md mx-auto mt-auto px-3">
      Du hast teilgenommen, aber möchtest deine Wette und Daten löschen? <a class="underline cursor-pointer hover:text-black" href="mailto:wendelin@peleska.at?subject=Babybet Daten löschen" target="_blank">Bitte schreib mir eine E-Mail.</a>
    </div>
    <Breadcrumps />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Directus } from '@directus/sdk'
import Breadcrumps from '../components/breadcrumps'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
const directus = new Directus('https://data.babybet.de/')

export default {
  name: 'Participants',
  components: {
    Breadcrumps,
  },
  props: {
    betId: String,
    group: String,
  },
  data() {
    return {
      search: '',
      bet: null,
      showPointInfo: false,
    }
  },
  async created() {
    this.bet = await directus.items('bet').readOne(this.betId, {
      fields: '*.*'
    })
    if (this.$router.currentRoute.value.query && this.$router.currentRoute.value.query.s) {
      this.search = this.$router.currentRoute.value.query.s
    }
  },
  computed: {
    searchedAnswers() {
      if (this.participants) {
        const searchArray = this.search.split(',')
  
        return this.participants
          .filter(({ name_person }) => {
            let found = false
            searchArray.forEach((term) => {
              const trimmedTerm = term.trim()
              if (trimmedTerm.startsWith('"') && trimmedTerm.endsWith('"')) {
                if (name_person.trim().toLowerCase() === trimmedTerm.replaceAll('"', '').toLowerCase()) {
                  found = true
                }
              } else if (name_person.trim().toLowerCase().indexOf(trimmedTerm.toLowerCase()) > -1) {
                found = true
              }
            })
            return found
          })
          .sort(({ name_person: a }, { name_person: b }) => a.localeCompare(b))
      }
      return null
    },
    winnerParticipants() {
      if (this.bet.correct_answers && this.participants) {
        return this.participants.filter(({ isWinner }) => isWinner)
      }
      return null
    },
    expectedDateOfBirth() {
      if (this.bet) {
        return dayjs(this.bet.expected_date_of_birth).format('DD.MM.YYYY')
      } else {
        return '-'
      }
    },
    dynamicColors() {
      if (this.bet) {
        switch(this.bet.color) {
          case 'gray':
            return {
              border: 'border-gray-500',
              title: 'from-gray-600 to-gray-500',
              button: 'to-gray-500 from-gray-500 hover:from-gray-600 hover:to-gray-600',
            }
          case 'red':
            return {
              border: 'border-red-500',
              title: 'from-gray-600 to-red-500',
              button: 'to-red-500 from-red-500 hover:from-red-600 hover:to-gray-600',
            }
          case 'yellow':
            return {
              border: 'border-yellow-500',
              title: 'from-gray-600 to-yellow-500',
              button: 'to-yellow-500 from-yellow-500 hover:from-yellow-600 hover:to-gray-600',
            }
          case 'blue':
            return {
              border: 'border-blue-500',
              title: 'from-gray-600 to-blue-500',
              button: 'to-blue-500 from-blue-500 hover:from-blue-600 hover:to-gray-600',
            }
          case 'indigo':
            return {
              border: 'border-indigo-500',
              title: 'from-gray-600 to-indigo-500',
              button: 'to-indigo-500 from-indigo-500 hover:from-indigo-600 hover:to-gray-600',
            }
          case 'purple':
            return {
              border: 'border-purple-500',
              title: 'from-gray-600 to-purple-500',
              button: 'to-purple-500 from-purple-500 hover:from-purple-600 hover:to-gray-600',
            }
          case 'pink':
            return {
              border: 'border-pink-500',
              title: 'from-gray-600 to-pink-500',
              button: 'to-pink-500 from-pink-500 hover:from-pink-600 hover:to-gray-600',
            }
          case 'green':
          default:
            return {
              border: 'border-green-500',
              title: 'from-gray-600 to-green-500',
              button: 'to-green-500 from-green-500 hover:from-green-600 hover:to-gray-600',
            }
        }
      }
      return {}
    },
    participants() {
      if (this.bet) {
        const participants = {}
        this.bet.answers.forEach(({ id, ...answer }) => {
          participants[id] = {
            ...answer,
            points: 0.0,
            categories: [],
          }
        })
        if (this.bet.correct_answers) {
          if (this.bet.options.includes('firstname')) {
            const firstnameWinners = []
            let fullPoints = true
            const correctAnswer = this.bet.correct_answers.bet_firstname.trim().toLowerCase()
            this.bet.answers.forEach(({ id, bet_firstname }) => {
              if (bet_firstname.trim().toLowerCase() == correctAnswer) {
                firstnameWinners.push(id)
              }
            })
            if (!firstnameWinners.length) {
              fullPoints = false
              this.bet.answers.forEach(({ id, bet_firstname }) => {
                if (bet_firstname.trim().toLowerCase().substr(0, 1) == correctAnswer.substr(0, 1)) {
                  firstnameWinners.push(id)
                }
              })
            }
            firstnameWinners.forEach((id) => {
              participants[id].points += fullPoints ? 1 : 0.5
              participants[id].categories.push('bet_firstname')
            })
          }
  
          if (this.bet.options.includes('weight')) {
            let weightWinners = []
            let nearestDiff = null
            const correctAnswer = Number(this.bet.correct_answers.bet_weight)
            this.bet.answers.forEach(({ id, bet_weight }) => {
              if (nearestDiff === null) {
                nearestDiff = Math.abs(correctAnswer - bet_weight)
                weightWinners.push(id)
              } else if (Math.abs(correctAnswer - bet_weight) == nearestDiff) {
                weightWinners.push(id)
              } else if (Math.abs(correctAnswer - bet_weight) < nearestDiff) {
                weightWinners = [id]
                nearestDiff = Math.abs(correctAnswer - bet_weight)
              }
            })
            weightWinners.forEach((id) => {
              participants[id].points += 1
              participants[id].categories.push('bet_weight')
            })
          }
  
          if (this.bet.options.includes('size')) {
            let sizeWinners = []
            let nearestDiff = null
            const correctAnswer = Number(this.bet.correct_answers.bet_size)
            this.bet.answers.forEach(({ id, bet_size }) => {
              if (nearestDiff === null) {
                nearestDiff = Math.abs(correctAnswer - bet_size)
                sizeWinners.push(id)
              } else if (Math.abs(correctAnswer - bet_size) == nearestDiff) {
                sizeWinners.push(id)
              } else if (Math.abs(correctAnswer - bet_size) < nearestDiff) {
                sizeWinners = [id]
                nearestDiff = Math.abs(correctAnswer - bet_size)
              }
            })
            sizeWinners.forEach((id) => {
              participants[id].points += 1
              participants[id].categories.push('bet_size')
            })
          }
  
          if (this.bet.options.includes('birthday')) {
            let birthdayWinners = []
            let nearestDiff = null
            const correctAnswer = dayjs(this.bet.correct_answers.bet_birthday)
            this.bet.answers.forEach(({ id, bet_birthday }) => {
              const dayjs_bet_birthday = dayjs(bet_birthday)
              if (nearestDiff === null) {
                nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthday))
                birthdayWinners.push(id)
              } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthday)) == nearestDiff) {
                birthdayWinners.push(id)
              } else if (Math.abs(correctAnswer.diff(dayjs_bet_birthday)) < nearestDiff) {
                birthdayWinners = [id]
                nearestDiff = Math.abs(correctAnswer.diff(dayjs_bet_birthday))
              }
            })
            birthdayWinners.forEach((id) => {
              participants[id].points += 1
              participants[id].categories.push('bet_birthday')
            })
          }
  
          if (this.bet.options.includes('birthtime')) {
            let birthtimeWinners = []
            let nearestDiff = null

            const splittedCorrect = this.bet.correct_answers.bet_birthtime.substr(0, 5).split(':')
            const correct = dayjs().hour(splittedCorrect[0]).minute(splittedCorrect[1]).second(0).millisecond(0)

            this.bet.answers.forEach(({ id, bet_birthtime }) => {
              const betSplitted = bet_birthtime.substr(0, 5).split(':')

              const betToday = dayjs().hour(betSplitted[0]).minute(betSplitted[1]).second(0).millisecond(0)
              const betYesterday = dayjs().add(1, 'day').hour(betSplitted[0]).minute(betSplitted[1]).second(0).millisecond(0)
              const betTomorrow = dayjs().subtract(1, 'day').hour(betSplitted[0]).minute(betSplitted[1]).second(0).millisecond(0)

              const diff = Math.min(Math.abs(correct.diff(betToday, 'minutes')), Math.abs(correct.diff(betYesterday, 'minutes')), Math.abs(correct.diff(betTomorrow, 'minutes')))
              
              if (nearestDiff === null) {
                nearestDiff = diff
                birthtimeWinners.push(id)
              } else if (diff == nearestDiff) {
                birthtimeWinners.push(id)
              } else if (diff < nearestDiff) {
                birthtimeWinners = [id]
                nearestDiff = diff
              }
            })
            birthtimeWinners.forEach((id) => {
              participants[id].points += 1
              participants[id].categories.push('bet_birthtime')
            })
          }
        }

        const maxPoints = Math.max(...Object.values(participants).map(({ points }) => points))

        return Object.entries(participants).map(([id, { points, bet_birthday, bet_birthtime, ...participant }]) => ({
          id,
          ...participant,
          points,
          bet_birthday: bet_birthday ? dayjs(bet_birthday).format('DD.MM.YYYY') : null,
          bet_birthtime: bet_birthtime ? bet_birthtime.substr(0, 5) : null,
          isWinner: points === maxPoints,
        }))
      }
      return null
    },
    correctBirthday() {
      if (this.bet && this.bet.correct_answers && this.bet.correct_answers.bet_birthday) {
        return dayjs(this.bet.correct_answers.bet_birthday).format('DD.MM.YYYY')
      }
      return null
    },
    correctBirthtime() {
      if (this.bet && this.bet.correct_answers && this.bet.correct_answers.bet_birthtime) {
        return this.bet.correct_answers.bet_birthtime.substr(0, 5)
      }
      return null
    },
  },
  methods: {
    clearSearch() {
      this.search = ''
    },
    togglePointInfo() {
      this.showPointInfo = !this.showPointInfo
      if (this.showPointInfo) {
        disableBodyScroll(this.$el)
      } else {
        enableBodyScroll(this.$el)
      }
    },
  }
}
</script>
