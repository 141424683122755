<template>
  <div class="bg-green-600 text-white p-5 flex text-2xl">
    Babybet
  </div>
  <router-view/>
</template>

<script>
export default {
  props: {
    group: String,
  },
}
</script>