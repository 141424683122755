<template>
  <div class="flex flex-col max-w-md items-center mx-auto p-5">
    <div class="font-bold m-10">
      Willkommen bei Babybet!
    </div>
    <div class="mx-10">
      Um bei einer Babybet mit zu machen, musst du einen direkten Link zu einer Wette bekommen. Um eine eigene Wette zu erstellen schreib mir bitte eine <a class="underline cursor-pointer hover:text-gray-500" href="mailto:wendelin@peleska.at?subject=Babybet Daten löschen" target="_blank">E-Mail</a>.
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
