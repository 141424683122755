export default [
  "Marie",
  "Maria",
  "Sophie",
  "Mila",
  "Marie",
  "Emilia",
  "Emma",
  "Mia",
  "Anna",
  "Lena",
  "Ella",
  "Sophie",
  "Lea",
  "Sophia",
  "Ida",
  "Lina",
  "Clara",
  "Lara",
  "Amelie",
  "Johanna",
  "Paula",
  "Charlotte",
  "Leni",
  "Luisa",
  "Greta",
  "Johanna",
  "Elisa",
  "Emily",
  "Lotta",
  "Laura",
  "Hanna",
  "Lia",
  "Charlotte",
  "Elisabeth",
  "Hannah",
  "Marlene",
  "Nele",
  "Sofia",
  "Katharina",
  "Maria",
  "Emilia",
  "Elif",
  "Leonie",
  "Mira",
  "Luisa",
  "Luise",
  "Carla",
  "Sophia",
  "Nora",
  "Elisa",
  "Antonia",
  "Carlotta",
  "Maya",
  "Romy",
  "Frieda",
  "Zeynep",
  "Frida",
  "Pauline",
  "Elena",
  "Lisa",
  "Luna",
  "Ava",
  "Helena",
  "Anna",
  "Ela",
  "Jana",
  "Julia",
  "Mara",
  "Mathilda",
  "Mina",
  "Paulina",
  "Isabella",
  "Juna",
  "Matilda",
  "Sarah",
  "Nur",
  "Eva",
  "Liya",
  "Luise",
  "Sara",
  "Victoria",
  "Lilly",
  "Maja",
  "Tilda",
  "Carlotta",
  "Alessia",
  "Klara",
  "Melina",
  "Nina",
  "Stella",
  "Marie",
  "Marlene",
  "Louisa",
  "Meryem",
  "Maria",
  "Su",
  "Amira",
  "Olivia",
  "Louisa",
  "Giulia",
  "Liv",
  "Thea",
  "Sofia",
  "Jule",
  "Luana",
  "Melek",
  "Pia",
  "Elif",
  "Josephine",
  "Leni",
  "Alina",
  "Amelia",
  "Amina",
  "Anastasia",
  "Aurelia",
  "Josephine",
  "Merle",
  "Zoe",
  "Malou",
  "Sofie",
  "Elina",
  "Elisabeth",
  "Josefine",
  "Katharina",
  "Linda",
  "Rosa",
  "Clara",
  "Greta",
  "Helene",
  "Louise",
  "Alicia",
  "Fiona",
  "Helin",
  "Martha",
  "Selin",
  "Victoria",
  "Zehra",
  "Ela",
  "Isabel",
  "Marleen",
  "Mia",
  "Amélie",
  "Asya",
  "Enna",
  "Maryam",
  "Melissa",
  "Melody",
  "Theresa",
  "Valentina",
  "Amalia",
  "Elise",
  "Hannah",
  "Josefine",
  "Lou",
  "Mathilda",
  "Matilda",
  "Pauline",
  "Aleyna",
  "Amalia",
  "Azra",
  "Fatima",
  "Isabel",
  "Livia",
  "Malia",
  "Mariam",
  "Miray",
  "Mona",
  "Christine",
  "Lara",
  "Martha",
  "Rosa",
  "Sara",
  "Viktoria",
  "Alma",
  "Anni",
  "Chiara",
  "Edda",
  "Elise",
  "Emelie",
  "Emilie",
  "Finja",
  "Jasmin",
  "Kira",
  "Lilli",
  "Lotte",
  "Mayla",
  "Vanessa",
  "Sophie",
  "Antonia",
  "Elena",
  "Eva",
  "Helena",
  "Isabelle",
  "Leonie",
  "Lina",
  "Lucia",
  "Mina",
  "Naz",
  "Alisa",
  "Alva",
  "Amy",
  "Annika",
  "Dalia",
  "Eleni",
  "Elli",
  "Hira",
  "Jette",
  "Leana",
  "Malou",
  "Marla",
  "Milena",
  "Philippa",
  "Selina",
  "Soraya",
  "Zoé",
  "Alexandra",
  "Ida",
  "Isabella",
  "Julia",
  "Lena",
  "Liv",
  "Lotta",
  "Magdalena",
  "Michelle",
  "Adriana",
  "Alice",
  "Alissa",
  "Bella",
  "Cleo",
  "Daria",
  "Dilara",
  "Emely",
  "Erva",
  "Esila",
  "Franziska",
  "Karla",
  "Leah",
  "Leyla",
  "Medina",
  "Milla",
  "Miriam",
  "Nela",
  "Nika",
  "Nisa",
  "Rosalie",
  "Ruby",
  "Tuana",
  "Valeria",
  "Viktoria",
  "Yasmin",
  "Johanna",
  "Amelie",
  "Barbara",
  "Frieda",
  "Julie",
  "Margarete",
  "Melody",
  "Mira",
  "Olivia",
  "Ronja",
  "Rose",
  "Valentina",
  "Ada",
  "Alexandra",
  "Amanda",
  "Amara",
  "Amine",
  "Angelina",
  "Anisa",
  "Annabelle",
  "Ayla",
  "Cataleya",
  "Claire",
  "Diana",
  "Elin",
  "Flora",
  "Hazal",
  "Isabell",
  "Isabelle",
  "Julie",
  "Karlotta",
  "Kiara",
  "Laila",
  "Lana",
  "Lilia",
  "Lorena",
  "Lorin",
  "Lynn",
  "Mavi",
  "Nila",
  "Noemi",
  "Rania",
  "Ronja",
  "Selma",
  "Sofie",
  "Zara",
  "Charlotte",
  "Elisabeth",
  "Alicia",
  "Azra",
  "Ella",
  "Friederike",
  "Gabriele",
  "Grace",
  "Hanna",
  "Jasmin",
  "Joleen",
  "Lotte",
  "Madita",
  "Mae",
  "Malina",
  "Marina",
  "Mathilde",
  "Medina",
  "Melek",
  "Naomi",
  "Paula",
  "Rosalie",
  "Sena",
  "Thea",
  "Zeynep",
  "Zoe",
  "Alara",
  "Alena",
  "Aliya",
  "Anouk",
  "Aurora",
  "Carolin",
  "Carolina",
  "Ceylin",
  "Defne",
  "Elissa",
  "Eslem",
  "Ina",
  "Joleen",
  "Linn",
  "Linnea",
  "Lou",
  "Lucia",
  "Lucy",
  "Madeleine",
  "Madita",
  "Malin",
  "Malina",
  "Mariella",
  "Marina",
  "Marleen",
  "Mathea",
  "Melis",
  "Melisa",
  "Merve",
  "Michelle",
  "Mieke",
  "Natalie",
  "Samira",
  "Yara",
  "Yuna",
  "Zoey",
  "Zümra",
  "Rosa",
  "Agnes",
  "Alexa",
  "Amélie",
  "Anastasia",
  "Christa",
  "Christiane",
  "Elli",
  "Emily",
  "Erika",
  "Esma",
  "Estelle",
  "Felicitas",
  "Florentine",
  "Henriette",
  "Hermine",
  "Hope",
  "Kaur",
  "Klara",
  "Lia",
  "Lisa",
  "Luana",
  "Mara",
  "Melina",
  "Philippa",
  "Romy",
  "Ruth",
  "Theresa",
  "Theresia",
  "Valeria",
  "Zahra",
  "Aişe",
  "Alexia",
  "Alia",
  "Almina",
  "Ana",
  "Anne",
  "Ariana",
  "Arina",
  "Asel",
  "Aylin",
  "Beatrice",
  "Betül",
  "Charlie",
  "Darya",
  "Dayana",
  "Derin",
  "Dina",
  "Ebrar",
  "Eda",
  "Elanur",
  "Eliza",
  "Ellen",
  "Emina",
  "Emmi",
  "Esther",
  "Felicitas",
  "Florentine",
  "Fritzi",
  "Hatice",
  "Helen",
  "Irma",
  "Jamila",
  "Jolina",
  "Karina",
  "Laia",
  "Larissa",
  "Lavin",
  "Lea-Sophie",
  "Lene",
  "Lenya",
  "Letizia",
  "Leya",
  "Liah",
  "Liana",
  "Liliana",
  "Lilith",
  "Lily",
  "Liz",
  "Louise",
  "Malak",
  "Marlena",
  "Marta",
  "Melia",
  "Meliha",
  "Milana",
  "Narin",
  "Neele",
  "Nia",
  "Noa",
  "Nour",
  "Pelin",
  "Safia",
  "Safiya",
  "Sherin",
  "Smilla",
  "Sunny",
  "Tessa",
  "Xenia",
  "Zahra",
  "Zeyneb",
  "Luise",
  "Margarete",
  "qizi",
  "Sophia",
  "Ada",
  "Amelia",
  "Ann",
  "Anouk",
  "Aurelia",
  "Ava",
  "Betül",
  "Cataleya",
  "Celine",
  "Cornelia",
  "Eleonora",
  "Eliana",
  "Eliza",
  "Emilie",
  "Fatima",
  "Fee",
  "Francesca",
  "Franziska",
  "Freya",
  "Fritzi",
  "Gül",
  "Hafsa",
  "Isabell",
  "Jolie",
  "Josephin",
  "Juli",
  "Juna",
  "Karolina",
  "Kate",
  "Lea",
  "Leandra",
  "Leia",
  "Lene",
  "Leonora",
  "Leyla",
  "Lilia",
  "Linn",
  "Liya",
  "Luz",
  "Mailin",
  "Maja",
  "Malia",
  "Margret",
  "Mari",
  "Matea",
  "Merle",
  "Michaela",
  "Mila",
  "Milena",
  "Monika",
  "Nami",
  "Nele",
  "Nora",
  "Paulina",
  "Petra",
  "Pia",
  "Rabia",
  "Sare",
  "Simone",
  "Sultan",
  "Tanja",
  "Ulrike",
  "Ursula",
  "Vera",
  "Veronika",
  "Zoé",
  "Aaliyah",
  "Adelina",
  "Aida",
  "Ajla",
  "Alaa",
  "Alba",
  "Alexa",
  "Almira",
  "Alya",
  "Amilia",
  "Annabell",
  "Annelie",
  "Asiya",
  "Beren",
  "Beyza",
  "Catalina",
  "Celine",
  "Ceren",
  "Ceyda",
  "Christina",
  "Clarissa",
  "Damla",
  "Dana",
  "Despina",
  "Destiny",
  "Dilay",
  "Ece",
  "Eliz",
  "Ellie",
  "Elodie",
  "Elsa",
  "Emilya",
  "Enya",
  "Esma",
  "Esra",
  "Evelyn",
  "Evin",
  "Feline",
  "Filippa",
  "Franka",
  "Gloria",
  "Hailey",
  "Hana",
  "Helene",
  "Henriette",
  "Hilal",
  "Hosna",
  "Ikra",
  "Inaya",
  "Inga",
  "Irem",
  "Iva",
  "Ivy",
  "Jonna",
  "Josephin",
  "Juli",
  "Karolina",
  "Khadija",
  "Kiana",
  "Kim",
  "Lale",
  "Leandra",
  "Leila",
  "Lenja",
  "Leona",
  "Leonora",
  "Leticia",
  "Lilou",
  "Lin",
  "Loki",
  "Lola",
  "Lucie",
  "Maila",
  "Mariana",
  "Medine",
  "Melike",
  "Meyra",
  "Milou",
  "Nadia",
  "Nahla",
  "Naya",
  "Nayla",
  "Nazlı",
  "Nelly",
  "Noelia",
  "Norah",
  "Pina",
  "Polly",
  "Rabia",
  "Rana",
  "Rebecca",
  "Safa",
  "Salma",
  "Sena",
  "Shana",
  "Sıla",
  "Simay",
  "Tara",
  "Tasnim",
  "Valerie",
  "Viola",
  "Yaren",
  "Yağmur",
  "Youssra",
  "Katharina",
  "Angelika",
  "Anna",
  "Barbara",
  "Carla",
  "Emilie",
  "Gerda",
  "Gisela",
  "Helene",
  "Johanne",
  "Katharina",
  "Lou",
  "Louise",
  "Rose",
  "Sofia",
  "Tomova",
  "Viktoria",
  "",
  "Adriana",
  "Agnieszka",
  "Agrippina",
  "Aleksandrova",
  "Alessia",
  "Alexia",
  "Alice",
  "Alva",
  "Amalie",
  "Amira",
  "Andrea",
  "Angelika",
  "Anita",
  "Annabelle",
  "Anni",
  "Apollonia",
  "Aria",
  "Ariana",
  "Astrid",
  "Avin",
  "Aylin",
  "Babette",
  "Banu",
  "Carina",
  "Carla",
  "Carolin",
  "Carolina",
  "Caroline",
  "Chanel",
  "Cheyenne",
  "Christina",
  "Concetta",
  "Cristina",
  "Denisa",
  "Diana",
  "Doris",
  "Ece",
  "Eda",
  "Elfi",
  "Eliz",
  "Ellen",
  "Emma",
  "Esila",
  "Eve",
  "Eylül",
  "Fabienne",
  "Felice",
  "Felicia",
  "Feline",
  "Filippa",
  "Fiona",
  "Gabriella",
  "Gisèle",
  "Helen",
  "Hifa",
  "Ilse",
  "Ina",
  "Ingeborg",
  "Ivanova",
  "Jael",
  "Jana",
  "Jane",
  "Jolene",
  "Joline",
  "Josefin",
  "June",
  "Kaja",
  "Karla",
  "Karlotta",
  "Kim",
  "Konstantina",
  "Kübra",
  "Larissa",
  "Laura",
  "Li",
  "Lidia",
  "Lieselotte",
  "Lili",
  "Lilly",
  "Linnea",
  "Liz",
  "Loni",
  "Lorena",
  "Lorin",
  "Lucy",
  "Luna",
  "Lynn",
  "Magdalene",
  "Malin",
  "Margareta",
  "Mariam",
  "Mariana",
  "Marianna",
  "Marianne",
  "Marlen",
  "Marli",
  "Mathea",
  "Maya",
  "Melin",
  "Mercan",
  "Meryem",
  "Meta",
  "Milla",
  "Minou",
  "Nerea",
  "Nicole",
  "Noa",
  "Panagiota",
  "Penelope",
  "Philine",
  "Polly",
  "Ravza",
  "Rebecca",
  "Regina",
  "Renée",
  "Ria",
  "Ricarda",
  "Rosemarie",
  "Ruby",
  "Rüya",
  "Sadar",
  "Sahra",
  "Salome",
  "Sarah",
  "Selma",
  "Serra",
  "Serwaa",
  "Sofi",
  "Soraya",
  "Stine",
  "Susan",
  "Susanne",
  "Tamara",
  "Teresa",
  "Thalia",
  "Tilda",
  "Trixi",
  "Valerie",
  "Vanessa",
  "Vincenza",
  "Yasmin",
  "Zehra",
  "Zoey",
  "Zümra",
  "Aaliya",
  "Adama",
  "Adar",
  "Adea",
  "Aimée",
  "Aischa",
  "Aisha",
  "Alana",
  "Alea",
  "Alenia",
  "Aliyah",
  "Amal",
  "Amely",
  "Amra",
  "Anastasija",
  "Andia",
  "Angela",
  "Anita",
  "Anna-Lena",
  "Annabel",
  "Antonie",
  "Aria",
  "Arya",
  "Asia",
  "Asli",
  "Asmin",
  "Ayda",
  "Ayça",
  "Ayşe",
  "Barbara",
  "Belinay",
  "Berenike",
  "Beritan",
  "Berra",
  "Betty",
  "Cansu",
  "Carlie",
  "Caroline",
  "Cathy",
  "Celin",
  "Chanel",
  "Chayenne",
  "Cheyenne",
  "Christine",
  "Cora",
  "Cécilia",
  "Céline",
  "Dalina",
  "Daniela",
  "Darina",
  "Davina",
  "Debora",
  "Delia",
  "Delina",
  "Dila",
  "Dorothea",
  "Dunya",
  "Eden",
  "Edina",
  "Egzona",
  "Ekaterina",
  "Elaine",
  "Elara",
  "Elea",
  "Elenia",
  "Eleysa",
  "Eliana",
  "Elida",
  "Elisea",
  "Elizabeth",
  "Elizan",
  "Elmira",
  "Emel",
  "Emine",
  "Enie",
  "Esin",
  "Estelle",
  "Evelin",
  "Fabienne",
  "Fanta",
  "Fatma",
  "Felicia",
  "Felina",
  "Feride",
  "Fine",
  "Fleur",
  "Florentina",
  "Francesca",
  "Gabriela",
  "Giada",
  "Gjemile",
  "Gretchen",
  "Hajar",
  "Halime",
  "Hanne",
  "Hayat",
  "Hedda",
  "Hedi",
  "Hiba",
  "Hilda",
  "Hilde",
  "Ilenia",
  "Ileyna",
  "Ilvy",
  "Imke",
  "Ines",
  "İrem",
  "Isa",
  "Isra",
  "Ivana",
  "Ivie",
  "Iwa",
  "Jael",
  "Janna",
  "Janne",
  "Jasmina",
  "Jean",
  "Jil",
  "Jill",
  "Joan",
  "Joline",
  "Jona",
  "Judith",
  "Jula",
  "Juliana",
  "Kaia",
  "Kamila",
  "Karoline",
  "Kenza",
  "Kowsar",
  "Kristina",
  "Lahja",
  "Lania",
  "Larin",
  "Larisa",
  "Layla",
  "Lejla",
  "Leoni",
  "Levke",
  "Leyna",
  "Lidia",
  "Lieselotte",
  "Lili",
  "Lille",
  "Lillian",
  "Lio",
  "Liora",
  "Lisann",
  "Lisbeth",
  "Liselotte",
  "Liva",
  "Lordina",
  "Lotti",
  "Lucile",
  "Luzi",
  "Luzie",
  "Lya",
  "Maali",
  "Mahinur",
  "Maia",
  "Maide",
  "Malea",
  "Manal",
  "Margarethe",
  "Marieke",
  "Marilou",
  "Maroua",
  "Marwa",
  "Mathilde",
  "Maxi",
  "Maxima",
  "May",
  "Melanie",
  "Melda",
  "Melinda",
  "Mercy",
  "Meva",
  "Mia-Sophie",
  "Mihrimah",
  "Mileen",
  "Mimi",
  "Minea",
  "Minel",
  "Miral",
  "Mo",
  "Nada",
  "Nala",
  "Naomi",
  "Nelia",
  "Nena",
  "Neyla",
  "Nike",
  "Nikita",
  "Nil",
  "Noor",
  "Oliwia",
  "Ona",
  "Palina",
  "Paola",
  "Pippa",
  "Piya",
  "Pola",
  "Polina",
  "Princess",
  "Rachel",
  "Rahel",
  "Ravza",
  "Raya",
  "Reyyan",
  "Riley",
  "Rim",
  "Rojda",
  "Romayssa",
  "Romi",
  "Romina",
  "Rosel",
  "Rosie",
  "Roya",
  "Roza",
  "Rubi",
  "Rüya",
  "Sabah",
  "Sahar",
  "Samantha",
  "Samiya",
  "Sare",
  "Sarina",
  "Saya",
  "Semra",
  "Sevda",
  "Shams",
  "Shanaya",
  "Sharon",
  "Shirin",
  "Sidra",
  "Silviya",
  "Sina",
  "Sinem",
  "Sira",
  "Stephanie",
  "Sumaya",
  "Sumeja",
  "Svea",
  "Tabea",
  "Talia",
  "Taliya",
  "Tamara",
  "Tiara",
  "Toni",
  "Tuba",
  "Uma",
  "Veronika",
  "Vesna",
  "Viana",
  "Vittoria",
  "Viyan",
  "Wilma",
  "Yael",
  "Yamina",
  "Yasmina",
  "Yasmine",
  "Zelal",
  "Zeyna",
  "Zina",
  "Zofia",
  "Özge",
  "Şefika",
  "Maria",
  "Elisabeth",
  "Nikolaus",
  "Jakob",
  "Josephine",
  "Stephanie",
  "Eliza",
  "Erna",
  "Ferdinande",
  "Ingrid",
  "Irene",
  "Jolie",
  "Maria",
  "Marie",
  "Micheline",
  "Moritz",
  "Pantera",
  "Pauline",
  "Petra",
  "Sophie",
  "Teresa",
  "Abu",
  "Adriane",
  "Adzo",
  "Alaïa",
  "Aleksandra",
  "Aloisia",
  "Amari",
  "Amidala",
  "Anaëlle",
  "Angelou",
  "Anita",
  "Anka",
  "Ann-Christin",
  "Annemarie",
  "Annikki",
  "Antoinette",
  "Anuschka",
  "Aroha",
  "Aurika",
  "Aurora",
  "Ava",
  "Awa",
  "Beatrice",
  "Belinay",
  "Belén",
  "Benedetta",
  "Bernadette",
  "Bernd",
  "Bettina",
  "Bibata",
  "Bilgin",
  "Birgit",
  "Bowie",
  "Bärbel",
  "Börte",
  "Carmen",
  "Carola",
  "Caroline",
  "Catherine",
  "Chanel",
  "Charly",
  "Chiara",
  "Christiane",
  "Claudia",
  "Constanze",
  "Cornelia",
  "Cäcilia",
  "Damayanti",
  "Dawood",
  "Delfina",
  "Demi",
  "Devi",
  "Diotima",
  "Dorothea",
  "Elfriede",
  "Elif",
  "Elke",
  "Emilia",
  "Emmelie",
  "Enna",
  "Erika",
  "Erna",
  "Ewa",
  "Fabiola",
  "Felicia",
  "Florence",
  "Francisca",
  "Francovna",
  "Franziska",
  "Frederice",
  "Frieda",
  "Friedel",
  "Gabriele",
  "Georgia",
  "Giesela",
  "Giuseppa",
  "Gloria",
  "Graziella",
  "Greta",
  "Haji",
  "Hannelore",
  "Helena",
  "Helma",
  "Hemma",
  "Henriette",
  "Ilham",
  "Ilona",
  "Ingelore",
  "Ini",
  "Irene",
  "Irmgard",
  "Irmlind",
  "Isabeau",
  "Isabella",
  "Jeanette",
  "Jolina",
  "Jona",
  "Josefine",
  "Jule",
  "Julia",
  "Julie",
  "Jutta",
  "Karoline",
  "Katharine",
  "Kathrin",
  "Katrin",
  "Kaya",
  "Klara",
  "Leonie",
  "Leonor",
  "Letizia",
  "Lilith",
  "Ling",
  "Linh",
  "Lotte",
  "Louisa",
  "Ludovica",
  "Mae",
  "Mai",
  "Maleika",
  "Malie",
  "Malou",
  "Manette",
  "Margareta",
  "Margaretha",
  "Marie-Theres",
  "Marieke",
  "Marija",
  "Marlies",
  "Marliese",
  "Marlise",
  "Marta",
  "Marthel",
  "Matea",
  "Mathea",
  "Mathilda",
  "Maxine",
  "Mercedes",
  "Mieke",
  "Mina",
  "Mira",
  "Monika",
  "Muzahem",
  "Nadine",
  "Naomi",
  "Nevenka",
  "Newiana",
  "Ninfa",
  "Niya",
  "Njwie",
  "Noja",
  "Nova",
  "Omega",
  "Paraskevi",
  "Patience",
  "Pauline",
  "Penelope",
  "Pernille",
  "Petra",
  "Philomena",
  "Pia",
  "Putri",
  "Qizi",
  "Regina",
  "Renesmee",
  "Rosemarie",
  "Rusalina",
  "Sandra",
  "Sanjay",
  "Saphira",
  "Sasi",
  "Sayela",
  "Sena",
  "Sofie",
  "Sonja",
  "Stine",
  "Sulaiman",
  "Sultan",
  "Susann",
  "Susanna",
  "Sylvia",
  "Tanja",
  "Theresa",
  "Therese",
  "Trudy",
  "Ulrike",
  "Ursula",
  "Vanessa",
  "Veronika",
  "Victoria",
  "Vittoria",
  "Vivien",
  "Vladislavovna",
  "Yahya",
  "Yanovna",
  "Yi",
  "Zahra",
  "Zaira",
  "Zoe",
  "Zora",
  "Aaliesha",
  "Aaliya",
  "Aaliyah",
  "Abdelkhalek",
  "Adalet",
  "Adele",
  "Adesuwa",
  "Aduke",
  "Aedah",
  "Aemilia",
  "Afomia",
  "Afriyie",
  "Agata",
  "Agie",
  "Aileen",
  "Ainhoa",
  "Aischa",
  "Aissata",
  "Aiwei",
  "Akhmedovna",
  "Akif",
  "Akiti",
  "Akua",
  "Alana",
  "Albane",
  "Albertina",
  "Albina",
  "Alea",
  "Alegra",
  "Alegría",
  "Alejandra",
  "Alejandrina",
  "Alejandro",
  "Aleksandra",
  "Aleksandrovna",
  "Alena",
  "Alessandra",
  "Aleyah",
  "Alima",
  "Alinda",
  "Aline",
  "Aliosmanova",
  "Alisa",
  "Aliyah",
  "Aliye",
  "Alliah",
  "Alma",
  "Almazovna",
  "Alrike",
  "Altanay",
  "Altay",
  "Alya",
  "Ama",
  "Amaal",
  "Amanda",
  "Amandine",
  "Amani",
  "Amata",
  "Amaya",
  "Ambra",
  "Amely",
  "Amil",
  "Amilia",
  "Amina",
  "Amrita",
  "Amy",
  "Ananda",
  "Andra",
  "Andrei",
  "Angel",
  "Angela",
  "Angelova",
  "Anh",
  "Anjali",
  "Anna-Elisa",
  "Anna-Maria",
  "Annabel",
  "Anne",
  "Annelie",
  "Anneliese",
  "Annemarie",
  "Annemie",
  "Annette",
  "Annie",
  "Annik",
  "Annina",
  "Anou",
  "Antoinette",
  "Antonina",
  "Anwar",
  "Apolonia",
  "Apostolova",
  "Aras",
  "Ariane",
  "Arife",
  "Arjani",
  "Armen",
  "Artemis",
  "Arya",
  "Asal",
  "Ascensión",
  "Asel",
  "Asena",
  "Asenova",
  "Aslanovna",
  "Astoria",
  "Asuncion",
  "Augusta",
  "Aurora",
  "Avelina",
  "Ayanda",
  "Aybüke",
  "Aydzhanova",
  "Aynur",
  "Ayse",
  "Ayyed",
  "Ayélé",
  "Ayşe",
  "Aşire",
  "Barnett",
  "Beatrice",
  "Bedriye",
  "Behiye",
  "Belinay",
  "Belle",
  "Benedetta",
  "Bensu",
  "Beren",
  "Beril",
  "Berit",
  "Bernadett",
  "Berrin",
  "Betty",
  "Beyda",
  "Bianca",
  "Bilyanova",
  "Biola",
  "Birgit",
  "Birgül",
  "Blanca",
  "Blandine",
  "Bo",
  "Borislavova",
  "Branimirova",
  "Brasilia",
  "Bruni",
  "Buglem",
  "Bulankete",
  "Büşra",
  "Caisey",
  "Calia",
  "Cara",
  "Carmela",
  "Carmen",
  "Cassia",
  "Catarina",
  "Catherine",
  "Cathleen",
  "Caylan",
  "Cağla",
  "Cebille",
  "Cecilia",
  "Celestine",
  "Celina",
  "Cennet",
  "Ceylin",
  "Chan",
  "Chantal",
  "Charlie",
  "Chiara",
  "Chihiro",
  "Ching",
  "Chioma",
  "Chloe",
  "Chrisi",
  "Christel",
  "Christin",
  "Chuyi",
  "Claire",
  "Clarissa",
  "Clarisse",
  "Claudia",
  "Cleo",
  "Constanze",
  "Consuela",
  "Cäcilia",
  "Cäcilie",
  "Cécile",
  "Dagmar",
  "Dahlia",
  "Dalia",
  "Daniela",
  "Danielle",
  "Deetje",
  "Dela",
  "Delal",
  "Delaya",
  "Denise",
  "Deniz",
  "Denizova",
  "Destiny",
  "Dewi",
  "Deysi",
  "Diamond",
  "Diba",
  "Dilay",
  "Dilayla",
  "Dilbera",
  "Dilgüşa",
  "Dimitrova",
  "Dina",
  "Dita",
  "Djamila",
  "Dmitrievna",
  "Dominika",
  "Dominique",
  "Dora",
  "Dorcas",
  "Dorota",
  "Douha",
  "Dua",
  "Duha",
  "Dunya",
  "Ebru",
  "Ecaterina",
  "Edda",
  "Eden",
  "Editha",
  "Eduarda",
  "Edze",
  "Efrossini",
  "Ehtiramovna",
  "Eilen",
  "Elahia",
  "Eleana",
  "Eleni",
  "Eleonore",
  "Elin",
  "Elina",
  "Elinor",
  "Elisabetta",
  "Elizabeth",
  "Elizan",
  "Eloisa",
  "Eloïse",
  "Elsa",
  "Else",
  "Elu",
  "Elvin",
  "Elvina",
  "Elyana",
  "Emely",
  "Emina",
  "Emine",
  "Emmi",
  "Emmy",
  "Emre",
  "Endegena",
  "Enyue",
  "Erdinch",
  "Erica",
  "Erol",
  "Erva",
  "Esmeralda",
  "Essotom",
  "Esther",
  "Etienne",
  "Eugenia",
  "Euphrasie",
  "Evangeline",
  "Evelin",
  "Eveline",
  "Evgenia",
  "Evin",
  "Ewa",
  "Ezo",
  "Fabiena",
  "Faith",
  "Farah",
  "Farize",
  "Fatiha",
  "Fatimatou",
  "Fatma",
  "Fatna",
  "Felicja",
  "Felina",
  "Felixina",
  "Fernanda",
  "Fida",
  "Fidan",
  "Filia",
  "Filicia",
  "Filipa",
  "Flavia",
  "Florence",
  "Florie",
  "Frederike",
  "Friedrich",
  "Frimpong",
  "Fynnja",
  "Félice",
  "Gabriel",
  "Garova",
  "Genoveva",
  "Georgeta",
  "Georgianna",
  "Geraldine",
  "Gesa",
  "Gina",
  "Ginkova",
  "Gisa",
  "Gita",
  "Giuseppina",
  "Gloria",
  "Gretchen",
  "Gyanbea",
  "Gyunaydinova",
  "Gökçe",
  "Gülenay",
  "Gülsüm",
  "Güneş",
  "Günterovna",
  "Habibatou",
  "Hajar",
  "Hajer",
  "Halima",
  "Halime",
  "Hana",
  "Hanieh",
  "Hanne",
  "Hanni",
  "Hayat",
  "Hazal",
  "Hazel",
  "Heather",
  "Hedi",
  "Heidi",
  "Heja",
  "Helga",
  "Hezam",
  "Hilda",
  "Hilde",
  "Hildegard",
  "Hira",
  "Hivda",
  "Hjortshøj",
  "Hollie",
  "Holly",
  "Honour",
  "Hristova",
  "Humeyra",
  "Hyudaet",
  "Hyuseinova",
  "Hélène",
  "Hülya",
  "Hüma",
  "Iasmin",
  "Ifeoma",
  "Igorevna",
  "Ihsan",
  "Ilayda",
  "Ilinca",
  "Ilvie",
  "Ilvy",
  "Ilyayda",
  "Indah",
  "Inga",
  "Inge",
  "Io",
  "Ioana",
  "Irene",
  "Irina",
  "Iris",
  "Irma",
  "Irmgard",
  "Irmina",
  "Isa",
  "Isabely",
  "Ishvari",
  "Ismayil",
  "Iuriivna",
  "Iva",
  "Izabela",
  "Izabella",
  "Jade",
  "Jamelia",
  "James",
  "Jamila",
  "Janine",
  "Jeanette",
  "Jeanine",
  "Jeanne",
  "Jeannette",
  "Jenaya",
  "Jennifer",
  "Jeta",
  "Jewell",
  "Jhomi",
  "Jil",
  "Joely",
  "Johana",
  "Johanne",
  "Jolien",
  "Josefa",
  "Josefina",
  "Joséphine",
  "Jovana",
  "Joy",
  "Jozefina",
  "Judith",
  "Juley",
  "Juliana",
  "Juliane",
  "Juline",
  "Junia",
  "Juno",
  "Kadira",
  "Kalea",
  "Karin",
  "Kasem",
  "Kassiopeia",
  "Kataleya",
  "Katharin",
  "Kathleen",
  "Katinka",
  "Kauane",
  "Kaya",
  "Kazadi",
  "Kebetat",
  "Kelly",
  "Keo",
  "Ketty",
  "Khalaf",
  "Khudhur",
  "Kiana",
  "Kiara",
  "Kifaah",
  "Kisimba",
  "Kitti",
  "Klementine",
  "Konstantinovna",
  "Krasimirova",
  "Kristin",
  "Kristina",
  "Kumiko",
  "Kyra",
  "Käthe",
  "Laetitia",
  "Laila",
  "Laris",
  "Laurenz",
  "Lauwrie",
  "Laye",
  "Lee",
  "Leevke",
  "Leilani",
  "Lenke",
  "Lenora",
  "Lenya",
  "Leo",
  "Leokadia",
  "Leonore",
  "Leontine",
  "Levana",
  "Lia-Marie",
  "Lian",
  "Liane",
  "Lianna",
  "Lieke",
  "Lien",
  "Lieve",
  "Lila",
  "Liliana",
  "Lilika",
  "Lilith",
  "Lilli",
  "Lillian",
  "Lillie",
  "Lilo",
  "Lilou",
  "Lily",
  "Line",
  "Linh",
  "Linni",
  "Linnéa",
  "Liselotte",
  "Lisha",
  "Lishi",
  "Liva",
  "Lola",
  "Loren",
  "Lou-Marion",
  "Louis",
  "Loïde",
  "Lu",
  "Luciana",
  "Ludwina",
  "Luiza",
  "Luka",
  "Lule",
  "Luzia",
  "Luzie",
  "Léanne",
  "Ma",
  "Magali",
  "Mahalia",
  "Mahjouba",
  "Mai",
  "Maike",
  "Makeda",
  "Malea",
  "Maliah",
  "Malie",
  "Malon",
  "Malu",
  "Malwina",
  "Mansah",
  "Manuela",
  "Marah",
  "Maraike",
  "Marei",
  "Maren",
  "Margaretha",
  "Margarethe",
  "Margit",
  "Margot",
  "Marie-Sol",
  "Marieluise",
  "Marietta",
  "Marita",
  "Marlie",
  "Marta",
  "Martina",
  "Maryna",
  "Maré",
  "Masal",
  "Masha",
  "Masod",
  "Masoud",
  "Massud",
  "Masuma",
  "Matija",
  "Maureen",
  "Maurice",
  "Mava",
  "Maxima",
  "Maxime",
  "May",
  "Mayaya",
  "Maykalova",
  "Maylin",
  "Maéva",
  "Mediha",
  "Medya",
  "Megan",
  "Meike",
  "Meliah",
  "Meliha",
  "Melissa",
  "Menoria",
  "Meral",
  "Mercedes",
  "Mercedesa",
  "Merel",
  "Mevsim",
  "Meyra",
  "Mie",
  "Mieke",
  "Mihaela",
  "Mihailova",
  "Mihriban",
  "Mihrimah",
  "Mikolaivna",
  "Milagros",
  "Milana",
  "Milas",
  "Minerva",
  "Minka",
  "Miranda",
  "Miraç",
  "Miriam",
  "Mirja",
  "Mirjana",
  "Mitkova",
  "Modesta",
  "Momchilova",
  "Moni",
  "Monique",
  "Munachimsom",
  "Muriel",
  "Musa",
  "Mustafa",
  "Muthoni",
  "Mutiara",
  "Nada",
  "Nadia",
  "Nadine",
  "Nadiye",
  "Nadja",
  "Naiara",
  "Nailantei",
  "Nalani",
  "Nandi",
  "Naomie",
  "Narin",
  "Nastia",
  "Natalia",
  "Natalie",
  "Natascha",
  "Natividad",
  "Navina",
  "Nazar",
  "Nazire",
  "Nduta",
  "Nehal",
  "Nehir",
  "Neransoula",
  "Nevin",
  "Nezi",
  "Neşe",
  "Nico",
  "Nida",
  "Nike",
  "Nikita",
  "Nilay",
  "Nilofah",
  "Nisa",
  "Njanja",
  "Noemie",
  "Nola",
  "Noomi",
  "Noor",
  "Nore",
  "Nova",
  "Nuo",
  "Nurbin",
  "Nuria",
  "Nyah",
  "Nyamekye",
  "Nüha",
  "Octavia",
  "Ode-Titilayo",
  "Odilia",
  "Oghosa",
  "Oluwadamilola",
  "Omosigho",
  "Onome",
  "Ornella",
  "Osatohanmwen",
  "Osazee",
  "Osinachi",
  "Ottilie",
  "Oyu",
  "Palomé",
  "Paola",
  "Paora",
  "Pari",
  "Pascale",
  "Patricia",
  "Peanie",
  "Pelina",
  "Peri",
  "Petarova",
  "Poppy",
  "Precious",
  "Princesa",
  "Purity",
  "Querine",
  "Rachael",
  "Rana",
  "Rangelova",
  "Raphaela",
  "Rebaz",
  "Rebeka",
  "Rei",
  "Revan",
  "Reyna",
  "Reyyan",
  "Rhabea",
  "Riana",
  "Rika",
  "Rike",
  "Rizvanovna",
  "Robin",
  "Rojna",
  "Romina",
  "Rosaria",
  "Rosie",
  "Rosina",
  "Rosmarie",
  "Roža",
  "Rukky",
  "Rumenova",
  "Ruža",
  "Sabine",
  "Sabrina",
  "Sadat",
  "Saif",
  "Salia",
  "Salomeh",
  "Samantha",
  "Sametova",
  "Samireva",
  "Sandova",
  "Sanjana",
  "Santina",
  "Sao",
  "Saroosh",
  "Sashova",
  "Savannah",
  "Sawdatu",
  "Sayge",
  "Sayuri",
  "Sebahat",
  "Sebiha",
  "Seena",
  "Selin",
  "Semiha",
  "Sergeevna",
  "Serpil",
  "Sevasti",
  "Sevde",
  "Severinova",
  "Sezen",
  "Shakira",
  "Sharon",
  "Sheirin",
  "Shirin",
  "Sibel",
  "Sieglinde",
  "Sila",
  "Silke",
  "Siwar",
  "Sofya",
  "Sokhna",
  "Sol",
  "Solin",
  "Sonea",
  "Sonia",
  "Sonja",
  "Sonne",
  "Sophia-Kathrin",
  "Sopie",
  "Soé",
  "Sparrow",
  "Stafanie",
  "Stefanija",
  "Stella",
  "Stephanie",
  "Stiliyan",
  "Stéphanie",
  "Sue",
  "Suhrana",
  "Suliman",
  "Sumaya",
  "Suna",
  "Sunita",
  "Susuruka",
  "Suzan",
  "Svea",
  "Svenja",
  "Swantje",
  "Sâra",
  "Sümeyye",
  "Tabea",
  "Tabitha",
  "Tak-Sin",
  "Talha",
  "Talia",
  "Tatjana",
  "Temur",
  "Tessa",
  "Theres",
  "Thy",
  "Tiana",
  "Tina",
  "Tincheva",
  "Toni",
  "Tonia",
  "Tova",
  "Trudi",
  "Tselot",
  "Tuana",
  "Tyra",
  "Ufuomaoghene",
  "Ulani",
  "Ute",
  "Valentine",
  "Verena",
  "Veselinova",
  "Vezire",
  "Viola",
  "Violet",
  "Violetova",
  "Virag",
  "Vivek",
  "Véronique",
  "Walburga",
  "Wali",
  "Wambui",
  "Wangari",
  "Wilder",
  "Wilhelmine",
  "Winou",
  "Xiu",
  "Xuan-An",
  "Yade",
  "Yahse",
  "Yamileth",
  "Yamina",
  "Yan",
  "Yaren",
  "Yarhelis",
  "Yassir",
  "Yennefer",
  "Yosias",
  "Yumi",
  "Yun",
  "Yur`evna",
  "Yusra",
  "Zayane",
  "Zdzislawa",
  "Zeinab",
  "Zennure",
  "Zhasmin",
  "Zhuoyue",
  "Zofia",
  "Zoi",
  "Zoly",
  "Zora",
  "Zozan",
  "Zufa",
  "Çağla",
  "Çilek",
  "Şahsenem",
  "Abby",
  "Abby-Lou",
  "Abbygale",
  "Abedah",
  "Abeera",
  "Abigail",
  "Acelya",
  "Adalida",
  "Adele",
  "Adeline",
  "Adelisa",
  "Adelya",
  "Aden",
  "Adina",
  "Adrienne",
  "Aganmathi",
  "Agrippina",
  "Ahmet",
  "Ahsen-Meryem",
  "Aidana",
  "Aikaterini",
  "Aimelis",
  "Aimiliana",
  "Ainara",
  "Aise",
  "Aivi",
  "Ajlin",
  "Ajna",
  "Ajnur",
  "Akilah",
  "Alaia",
  "Alanood",
  "Alaska",
  "Alegra",
  "Alessa",
  "Alessia-Amalia",
  "Aleya",
  "Aliana",
  "Alica",
  "Alicja",
  "Alida",
  "Aliki",
  "Alina-Fabienne",
  "Alisha",
  "Alisia",
  "Alissandra",
  "Almas",
  "Aloy",
  "Alysha-Sophie",
  "Alyson",
  "Alyssa",
  "Amaia",
  "Amaira",
  "Amali",
  "Amarachukwu",
  "Amarella",
  "Amaris",
  "Amatul",
  "Amelya",
  "Ameris",
  "Amia",
  "Amilya",
  "Aminata",
  "Aminenur",
  "Amitis",
  "An",
  "Ana-Maria",
  "Anabelle",
  "Anagha",
  "Anahita",
  "Anaiah",
  "Anano",
  "Anastazya",
  "Anaya",
  "Andiya",
  "Andjelina",
  "Andrea",
  "Andrija",
  "Anela",
  "Anesa",
  "Anfal",
  "Ange",
  "Angel",
  "Angelika",
  "Angeliki",
  "Ani",
  "Anica",
  "Aniek",
  "Anik",
  "Anika",
  "Anissa",
  "Anjarli-Sophie",
  "Anka",
  "Ankion",
  "Ann-Cathrin",
  "Ann-Kathrin",
  "Ann-Sophie",
  "Anna-Elisabeth",
  "Anna-Maria",
  "Anna-Marlena",
  "Anna-Sofia",
  "Annabella",
  "Annalena",
  "Annaleona",
  "Anneli",
  "Annemarie",
  "Annemrie",
  "Annie",
  "Annik",
  "Annisa",
  "Annnika",
  "Anousch",
  "Ansella",
  "Antigona",
  "Antonella",
  "Antonia-Sorina",
  "Anureet",
  "Anuschka",
  "Anushara",
  "Anwaar",
  "Any",
  "Apollonia",
  "Appolonie",
  "Ariadni",
  "Ariane",
  "Arianna",
  "Arijana",
  "Arin",
  "Ariyah",
  "Aroa",
  "Arsema",
  "Arwen",
  "Aryana",
  "Asenat",
  "Asil",
  "Asja",
  "Aslıhan",
  "Aslihan",
  "Asmaa",
  "Asmera",
  "Assal",
  "Assia",
  "Assija",
  "Assil",
  "Assna",
  "Asu",
  "Asude",
  "Atay",
  "Atia",
  "Atike",
  "Atrina",
  "Aulona",
  "Aurélie",
  "Ava-Maria",
  "Avelina",
  "Avery",
  "Aviella",
  "Avila-Juna",
  "Avin",
  "Avina",
  "Avril",
  "Avya",
  "Awat",
  "Aya-Estelle",
  "Ayca",
  "Aylis",
  "Ayliz",
  "Aymira",
  "Aynisa",
  "Aynişan",
  "Ayperi",
  "Ayse",
  "Aysenur",
  "Aysima",
  "Aysun",
  "Ayten",
  "Ayumi",
  "Ayșe",
  "Azahara",
  "Azzurra",
  "Bableen",
  "Banas",
  "Baraa",
  "Basma",
  "Batool",
  "Beauty",
  "Bedianur",
  "Behre",
  "Bela",
  "Benasir",
  "Benedetta",
  "Bengi",
  "Bente",
  "Beraet",
  "Berfin",
  "Berna",
  "Berrin",
  "Betelihem",
  "Beya",
  "Bianca",
  "Bianka",
  "Biav",
  "Biblina",
  "Bilge",
  "Billie-Fay",
  "Bina",
  "Bine",
  "Bisan",
  "Bo",
  "Braziliana",
  "Britney",
  "Büsra",
  "Büşra",
  "Caitlin",
  "Caja",
  "Calina",
  "Calixta",
  "Camila",
  "Camilia",
  "Camilla",
  "Canan",
  "Carl-Oscar",
  "Carlian",
  "Carlien",
  "Carlina",
  "Carmen",
  "Carol",
  "Carrie",
  "Cassandra",
  "Cassia",
  "Cattaleya",
  "Caziya",
  "Celeste",
  "Celia",
  "Celina",
  "Cemile",
  "Cennet",
  "Chahed",
  "Chakavak",
  "Charline",
  "Cherie",
  "Chiamaka",
  "Chloé",
  "Christelle",
  "Christiana",
  "Chrystel",
  "Ciara",
  "Ciellè-Saliha",
  "Cilina",
  "Ciyoma",
  "Claudia",
  "Clea",
  "Clelia",
  "Clementine",
  "Cosima",
  "Dala",
  "Dali",
  "Daliah",
  "Dalila",
  "Daly",
  "Dalya",
  "Daniya",
  "Danja",
  "Daphné",
  "Darin",
  "Daristan",
  "Dariya",
  "David",
  "Dawina",
  "Debrena",
  "Defne-Gizem",
  "Delana",
  "Delbar",
  "Delila",
  "Delinya",
  "Delisa",
  "Delveta",
  "Demira",
  "Denisa",
  "Desirée",
  "Diara",
  "Diba",
  "Dida",
  "Dijana",
  "Dilan",
  "Dilbar",
  "Dilek",
  "Dilpreet",
  "Dilruba",
  "Dione",
  "Dior",
  "Diora",
  "Divanka",
  "Divine",
  "Divya",
  "Diyar",
  "Djamila",
  "Djoumana",
  "Doha",
  "Doriana",
  "Dorothee",
  "Doğanay",
  "Dzevrije",
  "Dzhena",
  "Dünya",
  "Ebba",
  "Ecem",
  "Ecrin",
  "Edanur",
  "Edona",
  "Efrat",
  "Efrata",
  "Eftelya",
  "Ejona",
  "Ekaterini",
  "Elaina",
  "Elana",
  "Elayna",
  "Eldana",
  "Eldina",
  "Eleen",
  "Eleina",
  "Eleonore",
  "Eleyna",
  "Elia",
  "Elien",
  "Elifnaz",
  "Elifnisa",
  "Elifsu",
  "Eline",
  "Elis",
  "Elisavetha",
  "Elisia",
  "Elissavet",
  "Eliz-Alin",
  "Elizabet",
  "Elizabetta",
  "Elizaveta",
  "Ella-Marie",
  "Elleonora",
  "Elliana",
  "Ellina",
  "Elly",
  "Elma",
  "Elmedina",
  "Eloïse",
  "Elvira",
  "Elya",
  "Elysée",
  "Eléa",
  "Ema",
  "Emelly",
  "Emely-Isabella",
  "Emely-Jolie",
  "Emely-Klara",
  "Emese",
  "Emike",
  "Emiliy",
  "Emily-Sophie",
  "Emir",
  "Emissa",
  "Emma-Luise",
  "Emmelie",
  "Emmie",
  "Emmy",
  "Emnet",
  "Emy",
  "Enisa",
  "Enni",
  "Enora",
  "Enuara",
  "Era",
  "Eren",
  "Erika",
  "Erin",
  "Erina",
  "Erlisa",
  "Ermia",
  "Ermira",
  "Erona",
  "Eslem-Fatma",
  "Estelya",
  "Eunice",
  "Evi",
  "Evla",
  "Evîn",
  "Ewin",
  "Eyleen",
  "Eylül",
  "Eysa",
  "Ezzah",
  "Faden",
  "Fadime",
  "Fanny",
  "Farina",
  "Farnia",
  "Faroudja",
  "Fateme",
  "Fati",
  "Fatıma",
  "Fatou",
  "Fatoumata",
  "Feena",
  "Fehima",
  "Felicity",
  "Felipa",
  "Fentje",
  "Fernanda",
  "Filia",
  "Filiz",
  "Filomena",
  "Finnja",
  "Finya",
  "Firdaus",
  "Flavia",
  "Florine",
  "Flourish",
  "Franca",
  "Francisca",
  "Franzi",
  "Frederike",
  "Freja",
  "Frenja",
  "Freya",
  "Freyja",
  "Gabriella",
  "Gabrielle",
  "Gea",
  "Genan",
  "Genevieve",
  "Georgia",
  "Gergana",
  "Gesa",
  "Ghazel",
  "Giehan",
  "Gioia",
  "Giorgia",
  "Gisela",
  "Gisellè",
  "Giuliana",
  "Giuliana-Maria",
  "Gizem",
  "Gouri",
  "Grazia-Pia",
  "Grete",
  "Guadalupe",
  "Gulistan",
  "Gurleen",
  "Gülnar",
  "Hadil",
  "Hadizha",
  "Hadja",
  "Hadya",
  "Hafiza",
  "Hafsa",
  "Hafsa-Merve",
  "Halbast",
  "Haley",
  "Halina",
  "Halyn",
  "Hanaa",
  "Hanalei",
  "Hanan",
  "Hania",
  "Hanin",
  "Hanni",
  "Hargunpreet",
  "Harleen",
  "Harriet",
  "Hasnaa",
  "Hasret",
  "Hatiče-Melek",
  "Havva",
  "Hayal",
  "Hayet",
  "Hayley-Jade",
  "Haylie",
  "Heavenly",
  "Heba",
  "Hedy",
  "Heide",
  "Heidi",
  "Helan",
  "Heleni",
  "Helina",
  "Hella",
  "Helvin",
  "Helèna",
  "Hena",
  "Hennaya",
  "Herjin",
  "Hevi",
  "Hevin",
  "Hezal",
  "Hidaya",
  "Hifa",
  "Hifanur",
  "Hilary",
  "Hilla",
  "Hilma",
  "Hira-Necibe",
  "Hiranur",
  "Hiranur-Hayriye",
  "Hirut",
  "Hitap",
  "Hivdanur",
  "Hiyabel",
  "Huraa",
  "Hümaşah",
  "Hüsna",
  "Ida-Eleni",
  "Ida-Mey",
  "Iga",
  "İkra-Sultan",
  "Ilana",
  "Ilari",
  "Ilay",
  "Ilayda",
  "Ilayda-Emine",
  "Ilea",
  "Iliana",
  "Ilina",
  "Ilirijana",
  "Ilona",
  "Ilse",
  "İlsu",
  "Ilva",
  "Ilvi",
  "Inass",
  "Inayah",
  "İnci",
  "Indira",
  "Ine",
  "Inge",
  "Inna",
  "Inçi",
  "Inès",
  "İpek",
  "Ira",
  "Irena",
  "Irmak",
  "Isabella-Sophie",
  "Isla",
  "İsminaz",
  "Ivanina",
  "Jade",
  "Jahmila",
  "Jale",
  "Jalyna-Alicja",
  "Janaa",
  "Janelle",
  "Janet",
  "Jania",
  "Janina",
  "Janka",
  "Jara",
  "Jazleen",
  "Jeanetta",
  "Jeannice",
  "Jeelste",
  "Jehan",
  "Jela",
  "Jenna",
  "Jenniffer",
  "Jesnel",
  "Jetessa",
  "Jhanvi",
  "Jilan",
  "Jiuqi",
  "Joana",
  "Joebetshlyn",
  "Jolanda",
  "Jolene",
  "Jolie",
  "Jomana",
  "Jordis",
  "Josefa",
  "Josefina",
  "Josephina",
  "Josie",
  "Josie-Katelyn",
  "Joséphine",
  "Joudi",
  "Joulina",
  "Joy",
  "Joyce",
  "Judy",
  "Juha",
  "Julian",
  "Juliane",
  "Julika",
  "Juline",
  "July",
  "Jumana",
  "Junia",
  "Juno",
  "Jutta",
  "Juvana",
  "Kader",
  "Kadijat",
  "Kamilla",
  "Kanta",
  "Karalina",
  "Karolin",
  "Karthikaya",
  "Katarina",
  "Kate",
  "Katerina",
  "Katherine",
  "Kathrin",
  "Katica",
  "Katie",
  "Katinka",
  "Katja",
  "Katrine",
  "Kawtar",
  "Kellie",
  "Kelsi",
  "Kelycia",
  "Kendel",
  "Keslin",
  "Keti",
  "Ketsia",
  "Keyla-Sherin",
  "Kezia",
  "Kheira",
  "Khuslen",
  "Kimberley",
  "Kimberly",
  "Kimiya",
  "Kinza",
  "Klea",
  "Kornelia",
  "Kostana",
  "Kria",
  "Kristin",
  "Kristine",
  "Krystal",
  "Ksenia",
  "Kulthum",
  "Kumsal",
  "Käthe",
  "Laetitia",
  "Laja",
  "Lamar",
  "Lamija",
  "Lamiya",
  "Lamya",
  "Lana-Marie",
  "Lani",
  "Lanika",
  "Lanwe",
  "Larah",
  "Laria",
  "Larina",
  "Laura-Marie",
  "Lava",
  "Lavinea",
  "Lavinia",
  "Lawa",
  "Lawin",
  "Leah-Emilia",
  "Leanora",
  "Learta",
  "Lediona",
  "Leea",
  "Leia",
  "Leilani",
  "Lelia",
  "Lena-Sofie",
  "Leni-Marleen",
  "Lenina",
  "Lenna",
  "Lennar",
  "Lennja",
  "Leonor",
  "Leonore",
  "Leora",
  "Leyan",
  "Leyya",
  "Lia-Amalia",
  "Lia-Marleen",
  "Lia-Sophie",
  "Liah-Jouline",
  "Liah-Sophie",
  "Lian",
  "Lianna",
  "Lidya",
  "Lielet",
  "Liesel",
  "Lijeta",
  "Lilas",
  "Lilian",
  "Liliana-Milia",
  "Lilit",
  "Lilja",
  "Lilla",
  "Lilli-Marleen",
  "Lillianne",
  "Lillie",
  "Lillit",
  "Lilly-Marie",
  "Lilo",
  "Lilya",
  "Lilyana",
  "Lina-Marie",
  "Linas",
  "Linde",
  "Lioba",
  "Liona",
  "Lis",
  "Lisabet",
  "Lisanne",
  "Lise",
  "Lisette",
  "Lissa",
  "Litó",
  "Liyah",
  "Liyat",
  "Liza",
  "Lizda",
  "Lizz",
  "Ljiljana",
  "Loaven",
  "Lolle",
  "Lonia",
  "Lore",
  "Lorea",
  "Loreena",
  "Lorelly-Irya",
  "Lori",
  "Lotte-Fenja",
  "Louisa-Marie",
  "Luca",
  "Lucca",
  "Lucienne",
  "Lucinda",
  "Lucía",
  "Ludmilla",
  "Luka",
  "Lulya",
  "Luna-Maria",
  "Lunja",
  "Lusia",
  "Lyana",
  "Lydia",
  "Lynett",
  "Lyvia",
  "Maahi",
  "Maarja",
  "Madison",
  "Madleen",
  "Madleina",
  "Madlen",
  "Madlin",
  "Mado",
  "Madonna",
  "Mae",
  "Magbula",
  "Magdalena",
  "Maggie",
  "Mahya",
  "Mai",
  "Maike",
  "Mailin",
  "Mairi",
  "Maj",
  "Majesty",
  "Majide-Nur",
  "Malee",
  "Malekah",
  "Malena",
  "Malia-Nora",
  "Malisha",
  "Maliya",
  "Malou-Noemi",
  "Malu",
  "Malvin",
  "Malvina",
  "Mana",
  "Manahil",
  "Mane",
  "Maneli",
  "Marah",
  "Maranata",
  "Maraya",
  "Marcelina",
  "Mardjane",
  "Mareli",
  "Maren",
  "Maresa",
  "Margaret",
  "Margareta",
  "Margarete",
  "Margarita",
  "Maria-Roberta",
  "Marie-Betsalel",
  "Marie-Christin",
  "Marie-Lizou",
  "Mariel",
  "Mariele",
  "Marielle",
  "Mariem",
  "Marija",
  "Marileen",
  "Marita",
  "Marlen",
  "Marlien",
  "Marlies",
  "Marline",
  "Marlis",
  "Mary",
  "Marya",
  "Maryem",
  "Marysol",
  "María",
  "Masa",
  "Mascha",
  "Matea",
  "Mathild",
  "Matylda",
  "Mavis",
  "Maxie",
  "Maxime",
  "Maxina",
  "Maxine",
  "Maya-Ida",
  "Mayalina",
  "Mayari",
  "Mayleen",
  "Mayra",
  "Mayven",
  "Maëlle",
  "Maíra",
  "Meggy",
  "Mehtap",
  "Meike",
  "Meili",
  "Meissa",
  "Mejra",
  "Melahat",
  "Meletia",
  "Melidia",
  "Melija",
  "Melin",
  "Melina-Alessia",
  "Melodie",
  "Melody-Sophie",
  "Merdina",
  "Merie-Ann",
  "Meriem",
  "Merva",
  "Meryam",
  "Mesgana",
  "Meta",
  "Meyla",
  "Mi-Hwan",
  "Mia-Jolin",
  "Mia-Leni",
  "Mia-Lotta",
  "Micela",
  "Midina",
  "Mihrinur",
  "Mika",
  "Mila-Jasemin",
  "Mila-Sophie",
  "Milan",
  "Milanea",
  "Milea",
  "Milia",
  "Milika",
  "Milina",
  "Millie",
  "Minh",
  "Minna",
  "Minou",
  "Mirabel",
  "Mirana",
  "Miraç",
  "Mirha",
  "Mirija",
  "Mirijem",
  "Mirjana",
  "Mirna",
  "Mirra",
  "Miya",
  "Mofeyintoluwa",
  "Monia",
  "Monika",
  "Mora",
  "Morena",
  "Moyi",
  "Mozhde",
  "Mukanya",
  "Muna",
  "Munira",
  "Muslima",
  "Myline",
  "Myna",
  "Mónica",
  "Nadhifa",
  "Nadin",
  "Nadira",
  "Nadya",
  "Nafisa",
  "Nagma",
  "Naima",
  "Naja",
  "Nandinzaya",
  "Naomy",
  "Nargiz",
  "Nassima",
  "Nassira",
  "Natali",
  "Natalia",
  "Nathalie",
  "Nava",
  "Nazrin",
  "Naëlle",
  "Nedime",
  "Neea",
  "Nefeli",
  "Neha",
  "Nelli",
  "Nellissa",
  "Nenna",
  "Nesibe",
  "Nesrin",
  "Neva",
  "Nevin",
  "Nevra",
  "Neyli",
  "Nieke",
  "Nienke",
  "Nihal",
  "Niki",
  "Nikol",
  "Nikolina",
  "Nila-Soleil",
  "Nilay",
  "Nilaya",
  "Nireti",
  "Nisanur",
  "Nitya",
  "Niyan",
  "Njinga",
  "Noahrina",
  "Noara",
  "Noela",
  "Nola",
  "Noura",
  "Nouria",
  "Nousaiba",
  "Nozhan",
  "Noée",
  "Noël",
  "Nuda",
  "Nujin",
  "Nupal",
  "Nurcan",
  "Nurhana",
  "Nushan",
  "Nussayba",
  "Nóra",
  "Obehi",
  "Odosa",
  "Oluwatomisin",
  "Omaja",
  "Oona",
  "Oriana",
  "Orie",
  "Oscar",
  "Oskar",
  "Oualae",
  "Oumou",
  "Oya",
  "Paloma",
  "Panagiota",
  "Panisa",
  "Paniz",
  "Panthea",
  "Paraskeva",
  "Paris",
  "Parishay",
  "Parmida",
  "Patricia",
  "Payton",
  "Peia",
  "Pela",
  "Pembe",
  "Penelope",
  "Perla",
  "Petra",
  "Pheliz",
  "Philine",
  "Philomene",
  "Pich",
  "Polli",
  "Precious",
  "Princesha",
  "Prințesa",
  "Purity",
  "Quỳnh",
  "Rabea",
  "Rada",
  "Raffaela",
  "Rafif",
  "Ragna",
  "Rahima",
  "Rahime",
  "Rahma",
  "Raihana",
  "Rama",
  "Ramiza",
  "Ramona",
  "Randa",
  "Ranya",
  "Rawda",
  "Rayhan",
  "Rayé",
  "Razal",
  "Razia",
  "Rebeca",
  "Rebecca-Manelle",
  "Rebeka",
  "Rebekka",
  "Regina",
  "Reina",
  "Remas",
  "Remzije",
  "Rena",
  "Renad",
  "Renel",
  "Retag",
  "Reyhan",
  "Riccia",
  "Richemelle",
  "Riean",
  "Rike",
  "Rima",
  "Rina",
  "Rinesa",
  "Rita",
  "Rital",
  "Rittal",
  "Riya",
  "Riyana",
  "Roaa",
  "Roha",
  "Rojin",
  "Ronahi",
  "Ronda",
  "Ronia",
  "Ronja-Joulin",
  "Ronya",
  "Ros",
  "Rosabella",
  "Rosalita",
  "Rosemieke",
  "Rosi",
  "Rosie-Ange",
  "Rosita",
  "Rossel",
  "Roukaya",
  "Rubie",
  "Rubina",
  "Rufta",
  "Rufuta",
  "Rui-Ze",
  "Rukiya",
  "Rumeisa",
  "Ruqayyah",
  "Rushi",
  "Rylie",
  "Rümeysa",
  "Saadet",
  "Sabiha",
  "Sabina",
  "Sabrin",
  "Sabrina",
  "Sacharja",
  "Sadije",
  "Sadmira",
  "Safah",
  "Safiatou",
  "Safija",
  "Safijat",
  "Saher",
  "Sahra",
  "Saida",
  "Sainab",
  "Saira",
  "Saja",
  "Salam",
  "Salimata",
  "Salli",
  "Sally",
  "Salome",
  "Salsabeel",
  "Salwa",
  "Saly",
  "Samaira",
  "Samar",
  "Samra",
  "Samrawit",
  "Sana",
  "Sandra",
  "Sanija",
  "Sanjana",
  "Sanna",
  "Santa",
  "Saphira",
  "Sarat",
  "Saray",
  "Sarra",
  "Sarrah",
  "Sasha",
  "Savia",
  "Sayma",
  "Sayra",
  "Schifa",
  "Seadet",
  "Seba",
  "Sedef",
  "Sejdina",
  "Sela",
  "Selen",
  "Selena",
  "Sella",
  "Selmedina",
  "Sema",
  "Semira",
  "Senta",
  "Senudhi",
  "Sera",
  "Serifa",
  "Serin",
  "Serpil",
  "Setayesh",
  "Sevdie",
  "Sevgi",
  "Seylyan",
  "Shaam",
  "Shahd",
  "Shaina",
  "Sham",
  "Shamanta",
  "Shammah",
  "Shanaia",
  "Shanaja",
  "Shania",
  "Shanty",
  "Shantyana",
  "Sharia",
  "Shayleen",
  "Shelby",
  "Shewin",
  "Sheyma",
  "Shirley",
  "Shunem",
  "Sia",
  "Siba",
  "Sibel",
  "Sıdal",
  "Silan",
  "Silas",
  "Silen",
  "Silina",
  "Siloah",
  "Silvana",
  "Silvin",
  "Sinat",
  "Sirin",
  "Sirina",
  "Sivana",
  "Skyler",
  "Slava",
  "Slawa",
  "Soe",
  "Sofia-Maria",
  "Sofía",
  "Sojoud",
  "Solena",
  "Solin",
  "Solome",
  "Sonas",
  "Sonia",
  "Sonya",
  "Sophia-Elisabeth",
  "Sophie-Joy",
  "Soukaina",
  "Soumeya",
  "Soé",
  "Stefania",
  "Stefania-Andreia",
  "Stefanie",
  "Stera",
  "Stina",
  "Stine",
  "Subaya",
  "Sude",
  "Sudenaz",
  "Suela",
  "Sumayya",
  "Suna",
  "Sura",
  "Suri",
  "Susann",
  "Susanna",
  "Suvi",
  "Suzy",
  "Sveja",
  "Svenja",
  "Sydney",
  "Sârâ",
  "Süheyla",
  "Sümeyye",
  "Süveybe",
  "Taisia",
  "Talea",
  "Talina",
  "Taliyah",
  "Tami",
  "Tamia",
  "Tamika",
  "Tamina",
  "Tamira",
  "Tandi",
  "Tanisha",
  "Tanya",
  "Taraneh",
  "Tatjana",
  "Teodora",
  "Teresa",
  "Thala",
  "Thalia",
  "Theda",
  "Theodora",
  "Therese",
  "Thilda",
  "Tia",
  "Tiffany",
  "Tika",
  "Tilli",
  "Tina",
  "Tita",
  "Tori",
  "Tracy",
  "Troja",
  "Tuğra",
  "Tuğçe",
  "Türkan",
  "Umut",
  "Unik",
  "Uriel",
  "Ursula",
  "Vaiana",
  "Vaiola",
  "Valea",
  "Valyn",
  "Vania",
  "Vanya",
  "Varnikha",
  "Vejin",
  "Velenora",
  "Venecia",
  "Venezia",
  "Vera",
  "Verena",
  "Verona",
  "Veselina",
  "Victory",
  "Vida",
  "Videl",
  "Vildan",
  "Vilma",
  "Violeta",
  "Vita",
  "Vivi",
  "Viviana",
  "Vivien",
  "Wafa",
  "Wafaa",
  "Wanda",
  "Wassiliki",
  "Weronika",
  "Wiebke",
  "Wijdan",
  "Xia",
  "Yade",
  "Yasemin",
  "Yasminah",
  "Yassemin",
  "Yee",
  "Yella",
  "Yemen",
  "Ylvi",
  "Ylvie",
  "Yma",
  "Yoanna",
  "Youmna",
  "Youna",
  "Yousra",
  "Yuki",
  "Yurdagül",
  "Yusra",
  "Yüsra",
  "Zahide",
  "Zahima",
  "Zahira",
  "Zahraa",
  "Zaineb",
  "Zariah",
  "Zaynap",
  "Zemina",
  "Zenab",
  "Zeren",
  "Zerya",
  "Zeynab",
  "Zin",
  "Zine",
  "Zineb",
  "Zion",
  "Zlatica",
  "Zoi",
  "Zoie",
  "Zola",
  "Zora",
  "Zosan",
  "Zoë",
  "Zühra",
  "Ángela",
  "Çilay",
  "Élise",
  "Ömer",
  "Öykü",
  "Ülkü",
  "Şûra"
]