<template>
  <div class="flex flex-wrap p-2">
    <router-link
      :to="`/${group}`"
      class="cursor-pointer hover:underline"
    >
      Übersicht
    </router-link>
    <template v-if="betId">
      <BreadcrumpSeperator />
      <router-link
        :to="`/${group}/${betId}`"
        class="cursor-pointer hover:underline"
      >
        Wette
      </router-link>
      <BreadcrumpSeperator v-if="routeName !== 'Wette'" />
      <div
        v-if="['Wette abgeben', 'Wette abgegeben'].includes(routeName)"
      >
        Tipp abgeben
      </div>
      <div
        v-if="routeName === 'Teilnehmer'"
      >
        Teilnehmende
      </div>
    </template>
  </div>
</template>

<script>
import BreadcrumpSeperator from './seperator'

export default {
  name: 'Breadcrump',
  components: {
    BreadcrumpSeperator,
  },
  computed: {
    group() {
      return this.$router.currentRoute.value.params.group
    },
    betId() {
      return this.$router.currentRoute.value.params.betId
    },
    routeName() {
      return this.$router.currentRoute.value.name
    }
  },
  showView(view) {
    this.$emit('showView', view)
  },
}
</script>