<template>
  <Breadcrumps />
  <div class="flex flex-col mx-auto items-center my-10 px-3 min-h-screen">
    <div
      class="text-8xl text-center mt-5 mx-5"
    >
      Danke!
    </div>
    <div class="text-center text-2xl">
      Deine Wette wurde platziert.
    </div>
    <div class="max-w-md mt-10">
      <router-link :to="`/${group}/${betId}/list`" class="flex justify-center items-center p-5 bg-green-500 text-white rounded hover:bg-opacity-90 mb-10">
        Zeig mir alle Teilnehmenden
      </router-link>
      <router-link :to="`/${group}/${betId}`" class="flex justify-center items-center p-5 bg-blue-500 text-white rounded hover:bg-opacity-90">
        Zurück zur Wette
      </router-link>
    </div>
  </div>
  <Breadcrumps />
</template>

<script>
import Breadcrumps from '../components/breadcrumps'

export default {
  name: 'SetBetSuccess',
  components: {
    Breadcrumps,
  },
  props: {
    group: String,
    betId: String,
  },
}
</script>
